import moment from "moment";
import { BEGINNING_OF_TIME } from "./constants";

export const SECOND_IN_MILLISECONDS = 1000;
export const MINUTE_IN_MILLISECONDS = 60 * SECOND_IN_MILLISECONDS;

export function formatTimestampToDate(timestamp, format) {
  const msTimestamp = Math.floor(timestamp / 1000000);
  return moment(msTimestamp).format(format);
}

export function formatTimestampToGeneralDateFormat(
  timestamp,
  { short = false, full = false, onlyDate = false, onlyTime = false } = {}
) {
  const date = moment(timestamp);

  if (!date.isValid() || date === BEGINNING_OF_TIME) {
    return "-";
  }

  if (onlyTime) {
    return date.format("HH:mm");
  }

  if (onlyDate) {
    return date.format("DD MMM YYYY");
  }

  if (short) {
    return date.format("D/M/YY, HH:mm");
  }

  if (full) {
    return date.format("DD MMMM YYYY, HH:mm:ss");
  }

  return date.format("DD MMM YYYY, HH:mm");
}
