import i18next from "i18next";
import ListActions from "modules/list/actions";
import api from "services/api";
import { generatePath } from "react-router";
import notifications from "services/notifications";
import {
  deleteEdgeMachine,
  EDGE_MACHINES_MODULE,
  addEdgeMachinesDrawer,
  editHostPairingKeyModal,
  EDIT_HOST_PAIRING_KEY,
  edgeMachineDetailsFetcher,
  tagsCache,
  tagsFetcher,
  getPayload,
} from "state/cluster/services/edgemachines";
import { EdgeMachineSchema } from "utils/schemas";
import createFormActions from "modules/form/actions";
import store, { getStoreEntity } from "services/store";
import { formatTags } from "utils/presenters";
import { parseAppliances, parseTagsForInput } from "utils/parsers";
import history from "services/history";
import { CLUSTERS } from "utils/constants/routes";
import Validator from "services/validator";
import {
  ApplyIf,
  areValidEdgeHostIds,
  Missing,
} from "services/validator/rules";
import { APPLIANCE_STATES } from "utils/constants";
import ModalService from "services/modal";
import loginService from "services/loginService";
import { getCurrentProjectUidFromUrl } from "state/auth/selectors";
import { terminalRef } from "pages/clusters/appliances/remoteShell";
import withPolling from "utils/withPolling";

// It will need load on scroll, maybe find a way to use a fetcher
export const edgeMachinesListActions = new ListActions({
  hasPagination: true,
  persistFilters: true,
  schema: [EdgeMachineSchema],
  defaultQuery: {
    search: "",
    limit: 25,
    states: [],
    tags: [],
    architecture: "",
  },
  initialQuery() {
    return {
      search: "",
      limit: 25,
      states: [],
      tags: [],
      architecture: "",
    };
  },
  async fetchData(query) {
    const {
      offset,
      limit,
      continue: continueToken,
      states,
      search,
      architecture,
      tags,
    } = query;
    const apiUrl = "v1/dashboard/edgehosts/search";
    const edgeHostStates = !states.length
      ? APPLIANCE_STATES.map((state) => state.value)
      : states;
    const tenantFilters = [];

    if (search) {
      tenantFilters.push({
        property: "name",
        values: [search],
        operator: "contains",
      });
    }

    if (edgeHostStates.length) {
      tenantFilters.push({
        property: "state",
        values: edgeHostStates,
        operator: "eq",
      });
    }

    if (architecture) {
      tenantFilters.push({
        property: "architecture",
        values: [architecture],
        operator: "eq",
      });
    }

    if (tags) {
      tenantFilters.push({
        property: "tags",
        values: tags.map((tag) => {
          return tag.includes(":") ? tag.split(": ")?.join(":") : tag;
        }),
        operator: "eq",
      });
    }
    const payload = getPayload(tenantFilters);

    const continueQueryParam = continueToken
      ? `&continue=${continueToken}`
      : "";

    const data = await api.post(
      `${apiUrl}?limit=${limit}&offset=${offset}${continueQueryParam}`,
      payload
    );

    return {
      ...data,
      items: parseAppliances(data?.items || []),
    };
  },
});

export function onSelectApplianceTag(tag) {
  return async function thunk(dispatch, getState) {
    const currentFilterTags =
      getState().list?.[EDGE_MACHINES_MODULE]?.query?.tags || [];

    dispatch(
      edgeMachinesListActions.changeQuery({
        name: "tags",
        value: !currentFilterTags.includes(tag)
          ? [...currentFilterTags, tag]
          : currentFilterTags.filter((filterTag) => filterTag !== tag),
        module: EDGE_MACHINES_MODULE,
      })
    );
  };
}

const addMachinesValidator = new Validator();
addMachinesValidator.addRule(
  ["machineIds"],
  areValidEdgeHostIds({
    errorMessageText: i18next.t(
      "Some Edge Host IDs don't respect the kubernetes naming guidelines"
    ),
  })
);

export const addEdgeMachinesFormActions = createFormActions({
  validator: addMachinesValidator,
  init: () =>
    Promise.resolve({
      machineIds: [],
      machinesTags: {},
      machinesPairingKey: {},
      machineArchType: {},
    }),
  submit: async (data) => {
    const promises = data.machineIds.map((edgeHostUid) =>
      api.post("v1/edgehosts", {
        metadata: {
          name: edgeHostUid,
          uid: edgeHostUid,
          labels: formatTags(data.machinesTags[edgeHostUid] || []),
        },
        spec: {
          hostPairingKey: data.machinesPairingKey[edgeHostUid] || "",
          archType: data.machineArchType[edgeHostUid] || "",
        },
      })
    );

    const result = await Promise.allSettled(promises);

    let hasRejection = false;
    const invalidMachines = [];

    result.forEach(({ status, reason, value }, index) => {
      if (status === "rejected") {
        notifications.error({
          message: i18next.t("Something went wrong"),
          description: reason?.message,
        });

        invalidMachines.push(data.machineIds[index]);
        hasRejection = true;
      }

      if (status === "fulfilled") {
        notifications.success({
          message: i18next.t(
            'Machine "{{machineUid}}" has been added successfully',
            { machineUid: value?.uid }
          ),
        });

        const updatedValues = [...data.machineIds];
        updatedValues.splice(index, 1);
        store.dispatch(
          addEdgeMachinesFormActions.onChange({
            module: EDGE_MACHINES_MODULE,
            name: "machineIds",
            value: updatedValues,
          })
        );
      }
    });

    if (hasRejection) {
      store.dispatch(
        addEdgeMachinesFormActions.updateErrors({
          module: EDGE_MACHINES_MODULE,
          errors: [
            {
              result: true,
              field: "machineIds",
              invalidTags: [...invalidMachines],
            },
          ],
        })
      );

      throw new Error();
    }

    store.dispatch(edgeMachinesListActions.fetchItems(EDGE_MACHINES_MODULE));
    store.dispatch(refetchTags());
  },
});

export function openAddEdgeMachineDrawer() {
  return (dispatch) => {
    addEdgeMachinesDrawer
      .open()
      .then(() =>
        dispatch(
          addEdgeMachinesFormActions.submit({ module: EDGE_MACHINES_MODULE })
        )
      );
  };
}

export function onMachineDelete({ uid, name, projectUid }) {
  return (dispatch, getState) => {
    const isDetailsPage = getState().location.params.id;

    const headers = projectUid ? { ProjectUid: projectUid } : {};

    deleteEdgeMachine.open({ machineName: name }).then(async () => {
      try {
        await api.delete(`v1/edgehosts/${uid}`, null, { headers });
      } catch (e) {
        notifications.error({
          message: i18next.t(
            "Something went wrong when trying to delete the edge machine"
          ),
          description: e?.message,
        });
        return;
      }

      notifications.success({
        message: i18next.t('Machine "{{name}}" has been deleted', { name }),
      });

      dispatch(edgeMachinesListActions.fetchItems(EDGE_MACHINES_MODULE));

      if (isDetailsPage) {
        history.push(generatePath(CLUSTERS.ROOT, { tab: "appliances" }));
      }
    });
  };
}

function refetchTags() {
  return async function thunk(dispatch) {
    await dispatch(tagsCache.flushCache());
    dispatch(tagsFetcher.fetch());
  };
}

const editPairingKeyValidator = new Validator();
export const editPairingKeyActions = createFormActions({
  validator: editPairingKeyValidator,
  async init() {
    const machineDetails = getStoreEntity(
      editHostPairingKeyModal.data.guid,
      EdgeMachineSchema
    );

    return {
      tags: parseTagsForInput(machineDetails?.metadata?.labels) || [],
      remoteSsh: machineDetails?.spec?.tunnelConfig.remoteSsh === "enabled",
      remoteSshTempUser:
        machineDetails?.spec?.tunnelConfig.remoteSshTempUser === "enabled",
    };
  },
  submit: async (data) => {
    const machineDetails = getStoreEntity(
      editHostPairingKeyModal.data.guid,
      EdgeMachineSchema
    );

    const uid = machineDetails?.metadata?.uid;

    const metadataPayload = {
      metadata: {
        labels: formatTags(data.tags),
        name: machineDetails?.metadata?.name,
        uid: machineDetails?.metadata?.uid,
      },
    };

    const headers = machineDetails?.spec?.projectMeta?.uid
      ? { ProjectUid: machineDetails?.spec?.projectMeta?.uid }
      : {};

    const promises = [
      api.put(`v1/edgehosts/${uid}/meta`, metadataPayload, { headers }),
    ];

    promises.push(
      api.patch(`v1/edgehosts/${uid}/tunnelConfig`, {
        remoteSsh: data.remoteSsh ? "enabled" : "disabled",
        remoteSshTempUser: data.remoteSshTempUser ? "enabled" : "disabled",
      })
    );

    try {
      await Promise.all(promises);
      notifications.success({
        message: i18next.t(
          'Machine "{{machineUid}}" has been updated successfully',
          { machineUid: uid }
        ),
      });

      store.dispatch(refetchTags());
    } catch (err) {
      notifications.error({
        message: i18next.t("Something went wrong"),
        description: err?.message,
      });
      return;
    }
  },
});

export function onRemoteShellToggle(value) {
  return (dispatch) => {
    dispatch(
      editPairingKeyActions.onChange({
        value,
        name: "remoteSsh",
        module: EDIT_HOST_PAIRING_KEY,
      })
    );
    if (!value) {
      dispatch(
        editPairingKeyActions.onChange({
          value: false,
          name: "remoteSshTempUser",
          module: EDIT_HOST_PAIRING_KEY,
        })
      );
    }
  };
}

export const remoteShellModal = new ModalService("remoteShell");

const remoteShellFormValidator = new Validator();
remoteShellFormValidator.addRule(
  ["user", "password"],
  ApplyIf((v, k, data) => !data.isRemoteSshTempUserEnabled, Missing())
);

function waitForFirstMessage(socket) {
  return new Promise((resolve, reject) => {
    socket.addEventListener("message", function handler(event) {
      socket.removeEventListener("message", handler);

      try {
        const eventData = JSON.parse(event.data);
        if (eventData?.status === "error") {
          reject(eventData);
        } else {
          resolve(event.data);
        }
      } catch (error) {
        resolve(event.data);
      }
    });
  });
}

async function connectWebSocket(credentials = {}) {
  const { user, password } = credentials;
  const state = store.getState();
  const authToken = loginService.getAuthorizationToken();
  const edgeHost = edgeMachineDetailsFetcher?.selector(state)?.result || {};
  const projectUid = getCurrentProjectUidFromUrl(state);
  const isRemoteSshTempUserEnabled =
    edgeHost?.spec?.tunnelConfig?.remoteSshTempUser === "enabled";
  const socket = new WebSocket(
    `/v1/ssh-ws/connect?Authorization=${authToken}&ProjectUid=${projectUid}`
  );
  const { cols = 24, rows = 80 } = terminalRef.current.dimensions;

  const pollPingPong = withPolling(() => {
    socket.send(JSON.stringify({ type: "ping" }));
    return Promise.reject();
  }, 30000);

  const message = {
    uid: edgeHost?.metadata?.uid,
    kind: "edgehost",
    terminal: {
      rows,
      cols,
    },
    ...(!isRemoteSshTempUserEnabled
      ? {
          user,
          password,
        }
      : {}),
  };

  function attemptToConnect() {
    return new Promise((resolve, reject) => {
      socket.onopen = async () => {
        terminalRef.current.terminal.clear();
        socket.send(JSON.stringify(message));
        terminalRef.current.terminal.writeln("Connecting...");

        try {
          const firstMsg = await waitForFirstMessage(socket);
          pollPingPong.start();
          terminalRef.current.terminal.writeln("Connected...");
          terminalRef.current.terminal.writeln(firstMsg);

          terminalRef.current.terminal.onData((data) => socket.send(data));

          socket.onmessage = (event) => {
            try {
              const message = JSON.parse(event.data);

              if (message.type !== "pong") {
                terminalRef.current.terminal.write(event.data);
              }
            } catch (e) {
              terminalRef.current.terminal.write(event.data);
            }
          };
          socket.onclose = async () => {
            terminalRef.current.terminal.writeln(
              "\r\nConnection closed. Please refresh the browser to login again"
            );
            pollPingPong.stop();
            await store.dispatch(
              openRemoteShellConnection({ reason: "inactive" })
            );
          };
          resolve();
        } catch (error) {
          await store.dispatch(
            openRemoteShellConnection({
              reason: "error",
              errorMessage: error.message,
            })
          );
          terminalRef.current.terminal.writeln(
            `Something went wrong: ${error.message}`
          );
          pollPingPong.stop();
          await store.dispatch(
            remoteShellFormActions.onChange({
              module: "remoteShell",
              name: "error",
              value: error.message,
            })
          );
          reject(error.message);
        }
      };
    });
  }

  await attemptToConnect();
}

export const remoteShellFormActions = createFormActions({
  validator: remoteShellFormValidator,
  init: () => {
    const edgeHost =
      edgeMachineDetailsFetcher?.selector(store.getState())?.result || {};
    const isRemoteSshTempUserEnabled =
      edgeHost?.spec?.tunnelConfig?.remoteSshTempUser === "enabled";
    return Promise.resolve({
      user: "",
      password: "",
      isRemoteSshTempUserEnabled,
      error: "",
    });
  },
  submit: async ({ user, password }) => {
    store.dispatch(
      remoteShellFormActions.onChange({
        module: "remoteShell",
        name: "error",
        value: "",
      })
    );
    await connectWebSocket({ user, password });
  },
});

export function openRemoteShellConnection(options = {}) {
  return async (dispatch, getState) => {
    await dispatch(remoteShellFormActions.init({ module: "remoteShell" }));
    const edgeHost =
      edgeMachineDetailsFetcher?.selector(getState())?.result || {};
    const isRemoteSshTempUserEnabled =
      edgeHost?.spec?.tunnelConfig?.remoteSshTempUser === "enabled";
    const { reason = "", errorMessage = "" } = options;
    if (!isRemoteSshTempUserEnabled || reason) {
      remoteShellModal.open({ reason, errorMessage }).then(() => {
        return dispatch(
          remoteShellFormActions.submit({ module: "remoteShell" })
        );
      });
    } else {
      try {
        await connectWebSocket();
      } catch (error) {}
    }
  };
}

export function onHostPairingKeyEdit({ guid }) {
  return (dispatch) => {
    const machineDetails = getStoreEntity(guid, EdgeMachineSchema);
    const machineUid = machineDetails.metadata.uid;
    const isDetailsPage = store.getState().location.params.id;

    editHostPairingKeyModal.open({ guid }).then(async () => {
      await dispatch(
        editPairingKeyActions.submit({ module: EDIT_HOST_PAIRING_KEY })
      );

      if (isDetailsPage) {
        await dispatch(edgeMachineDetailsFetcher.fetch(machineUid));
      } else {
        await dispatch(
          edgeMachinesListActions.fetchItems(EDGE_MACHINES_MODULE)
        );
      }
    });
  };
}

export function onMachineIdsChange(value) {
  return (dispatch, getState) => {
    dispatch(
      addEdgeMachinesFormActions.batchChange({
        module: EDGE_MACHINES_MODULE,
        updates: {
          machineIds: value,
          machineArchType: value.reduce((acc, curr) => {
            const machineArchType =
              getState().forms?.edgeMachines.data.machineArchType;

            if (!machineArchType[curr]) {
              acc[curr] = "amd64";
            }

            return { ...machineArchType, ...acc };
          }, {}),
        },
      })
    );

    const errors = getState().forms?.edgeMachines?.errors || [];

    if (errors.length > 0) {
      const updatedErrors = errors.map((error) => {
        if (error.field === "machineIds") {
          error.invalidTags = [...error.invalidTags].filter((tag) =>
            value.includes(tag)
          );
          if (error.invalidTags.length === 0) {
            error.result = false;
          }
        }

        return error;
      });

      dispatch(
        addEdgeMachinesFormActions.updateErrors({
          module: EDGE_MACHINES_MODULE,
          errors: updatedErrors,
        })
      );

      dispatch(
        addEdgeMachinesFormActions.validateField({
          module: EDGE_MACHINES_MODULE,
          name: "machineIds",
        })
      );
    }
  };
}
