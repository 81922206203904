import { keyedDataFetcher } from "modules/dataFetcher";
import api from "services/api";
import { getRawCluster } from "../selectors/details";
import { ClusterProfileSchema } from "utils/schemas";
import { createProfileDiffModule } from "modules/profileDifferentiator";
import { profileModule } from "state/cluster/services";
import { createSelector } from "reselect";
import {
  clusterConfigFormActions,
  fetchClusterProfile,
} from "../actions/details";

export const clusterRevisionsFetcher = keyedDataFetcher({
  selectors: ["cluster-revisions"],
  async fetchData([_1, clusterUid]) {
    const data = await api.get(`v1/spectroclusters/${clusterUid}/revisions`);
    return data.spcRevisions || [];
  },
});

export const clusterRevisionFetcher = keyedDataFetcher({
  schema: [ClusterProfileSchema],
  selectors: [
    "cluster-revisions",
    (state) => getRawCluster(state)?.metadata?.uid,
  ],
  async fetchData([_1, clusterUid, revisionUid]) {
    const data = await api.get(
      `v1/spectroclusters/${clusterUid}/revisions/${revisionUid}/profiles`
    );

    return data.profiles.map((profile) => {
      return {
        ...profile,
        metadata: {
          ...profile.metadata,
          uid: `${revisionUid}-${profile.metadata.uid}`,
        },
        spec: {
          published: {
            packs: profile.spec.packs.map((pack) => ({
              metadata: {
                ...pack.metadata,
                uid: `${revisionUid}-${pack.metadata.uid}`,
              },
              spec: {
                ...pack.spec,
                manifests: (pack.spec.manifests || pack.manifests || []).map(
                  (manifest) => ({
                    ...manifest,
                    uid: `${revisionUid}-${manifest.uid}`,
                    revisionUid,
                  })
                ),
              },
            })),
          },
        },
      };
    });
  },
});

export const profileUpdatesDiffModule = createProfileDiffModule({
  name: "profile-updates",
  reviewVariables: true,
  selectors: {
    getTargetProfiles: () => profileModule.state?.profiles || [],
    getCurrentProfiles: () => profileModule.state?.initialProfiles || [],
    getValues: () => profileModule.state?.values || {},
    getEntities: (state) => state.entities || {},
  },
  onReviewComplete: () => async (dispatch) => {
    dispatch(clusterConfigFormActions.submit({ module: "clusterConfig" }));
  },
  onLayerValuesChange: (layer, values) => (dispatch) => {
    profileModule.actions.dispatch({
      type: "PROFILE_STACK_VALUE_CHANGE",
      values,
      layer,
    });
  },
  onVariablesValuesChange: (variables) => (dispatch) => {
    dispatch(
      profileModule.variableFormActions.batchChange({
        module: profileModule.variableFormModule,
        updates: {
          variables,
        },
      })
    );
  },
});

export const revisionDiffModule = createProfileDiffModule({
  name: "revision-restore",
  selectors: {
    getTargetProfiles: createSelector(
      () => revisionDiffModule.services.diffSummaryModal.data,
      () => revisionDiffModule.services.diffReviewModal.data,
      (state) => state,
      () => profileModule.state?.profiles,
      (diffModalData, reviewModalData, state, currentProfiles) => {
        const revisionData = clusterRevisionFetcher
          .key(diffModalData?.revisionUid || reviewModalData?.revisionUid)
          .selector(state)?.result;
        return revisionData || currentProfiles || [];
      }
    ),

    getCurrentProfiles: () => profileModule.state?.profiles || [],
    getValues: createSelector(
      () => revisionDiffModule.services.diffSummaryModal.data,
      () => revisionDiffModule.services.diffReviewModal.data,
      (state) => state,
      (diffModalData, reviewModalData, state) => {
        const moduleValues = profileModule.state?.values || {};
        const revisionData =
          clusterRevisionFetcher
            .key(diffModalData?.revisionUid || reviewModalData?.revisionUid)
            .selector(state)?.result || [];

        const revisionValues = revisionData.reduce((acc, template) => {
          return {
            ...acc,
            ...template.spec.published.packs.reduce((acc, pack) => {
              return {
                ...acc,
                [pack.guid]: pack.spec.values,
              };
            }, {}),
          };
        }, {});
        return {
          ...moduleValues,
          ...revisionValues,
        };
      }
    ),
    getEntities: (state) => state.entities || {},
  },
  onReviewComplete: () => async (dispatch, getState) => {
    const clusterUid = getRawCluster(getState())?.metadata?.uid;
    const revisionUid =
      revisionDiffModule.services.diffReviewModal.data?.revisionUid ||
      revisionDiffModule.services.diffSummaryModal.data?.revisionUid;
    const restorePromise = api.patch(
      `v1/spectroclusters/${clusterUid}/revisions/${revisionUid}/revert`
    );

    await restorePromise;
    dispatch(clusterRevisionsFetcher.key(clusterUid).fetch());
    dispatch(fetchClusterProfile({ withConfiguration: true }));
  },
});
