import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import EditorLayout, {
  EditorSlot,
  FooterSlot,
  SidebarSlot,
} from "components/common/layouts/Editor";
import Modal from "components/ui/Modal";
import DocLink from "components/common/DocLink";
import Icon from "components/ui/Icon";

import ProfilesList from "./ProfilesList";
import Button, { TextButton } from "components/ui/Button";
import { lightPurpleGray, yellow } from "utils/constants/colors";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import EditorSplitTitle, {
  PackTitle,
} from "components/common/ClusterProfiles/EditorSplitTitle";
import Alert from "components/common/Alert";
import Editor from "components/common/Editor";
import { connectField, createForm } from "modules/form";
import { faBracketsCurly, faCircle } from "@fortawesome/pro-light-svg-icons";
import Switch from "components/common/Switch";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import ProfileVariablesDiffs from "./ProfileVariablesDiffs";
import { PROFILE_VARIABLES_LAYER_ID } from "modules/profileDifferentiator";
import { Tooltip } from "antd";
import { PackContainer } from "components/common/PackNameBlocks";

const WarningIcon = styled(Icon)`
  color: ${yellow};
  font-size: 16px;
  margin-right: 6px;
`;

const MainWrap = styled.div`
  margin: -24px;
  height: calc(100% + 48px);
`;

const TitleBanner = styled.div`
  width: 100%;

  ${PackContainer} {
    justify-content: space-between;
  }
`;

const ActionsWrap = styled.section`
  display: flex;
  gap: 8px;
`;

const FooterWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 12px;
  border-top: 1px solid #ddd;
`;

const ProfileVariablesLayer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  background: ${lightPurpleGray};
  margin-top: 8px;
  cursor: pointer;
`;

const VariablesLayerTitle = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  gap: 12px;

  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  color: #494492;
`;

const MODAL_SECTIONS = {
  VALUES_FORM: "values-form",
  PROFILE_VARIABLES: PROFILE_VARIABLES_LAYER_ID,
};

function getLayerBannerInfo(layer) {
  return {
    displayName:
      layer?.pack?.spec?.displayName || layer?.pack?.spec?.name || layer?.name,
    tag: layer?.spec?.version || layer?.version || "",
    logoUrl: layer?.spec?.logoUrl || layer?.pack?.spec?.logoUrl,
    addonType:
      layer?.kind ||
      layer?.type ||
      layer?.pack?.spec?.addonType ||
      layer?.pack?.spec?.type,
  };
}

const ConnectedEditor = connectField(Editor);

function ClusterPackDiffsModal({
  profilesDiffs,
  onValuesChange,
  selectedLayerGuid,
  selectedProfileGuid,
  modalService,
  isSubmitting = false,
  isRepaveRequired = false,
  readOnly = false,
  modalOpened = false,
  canUpdateLater = false,
  cloudType,
  originalValues = {},
  valuesForm,
  variablesData = {},
  onSelectProfileVariablesLayer,
  reviewVariables = false,
  ...rest
}) {
  const { t } = useTranslation();
  const [reviewedLayers, setReviewedLayers] = useState([]);
  const {
    variableChanges = [],
    reviewedLayers: reviewedVariableLayers = [],
    hasVariableChanges = false,
    diffsWithErrors = [],
  } = variablesData;
  const confirmLabel = isRepaveRequired ? t("Update") : t("Apply Changes");
  const areVariablesReviewed =
    reviewedVariableLayers?.length === variableChanges?.length;

  const section = useMemo(() => {
    if (selectedLayerGuid === PROFILE_VARIABLES_LAYER_ID) {
      return MODAL_SECTIONS.PROFILE_VARIABLES;
    }

    return MODAL_SECTIONS.VALUES_FORM;
  }, [selectedLayerGuid]);

  const ValuesForm = useMemo(
    () =>
      createForm({
        actions: valuesForm.actions,
        Component({ children }) {
          return children;
        },
      }),
    [valuesForm.actions]
  );

  useEffect(() => {
    if (!modalOpened) {
      setReviewedLayers([]);
    }
  }, [modalOpened]);

  useEffect(() => {
    if (
      !selectedLayerGuid ||
      reviewedLayers.includes(selectedLayerGuid) ||
      !modalOpened ||
      selectedLayerGuid === PROFILE_VARIABLES_LAYER_ID
    ) {
      return;
    }

    setReviewedLayers((prevState) => [...prevState, selectedLayerGuid]);
  }, [selectedLayerGuid, reviewedLayers, modalOpened]);

  const allLayers = useMemo(() => {
    const packs = (profilesDiffs || []).flatMap((profile) => profile.packs);
    const manifests = packs.flatMap((profile) => profile.manifests);

    return [...packs, ...manifests];
  }, [profilesDiffs]);

  const selectedLayer = useMemo(() => {
    return allLayers.find(
      ({ current, target }) =>
        target?.manifest?.guid === selectedLayerGuid ||
        current?.manifest?.guid === selectedLayerGuid ||
        target?.pack?.guid === selectedLayerGuid ||
        current?.pack?.guid === selectedLayerGuid
    );
  }, [selectedLayerGuid, allLayers]);

  const showDiff = !["pack-added", "manifest-added"].includes(
    selectedLayer?.type
  );
  const disableConfirm =
    reviewedLayers.length !== allLayers.length ||
    !areVariablesReviewed ||
    diffsWithErrors.length;

  const editorInfo = useMemo(() => {
    const { current, target } = selectedLayer || {};
    const currentInfo = current?.manifest || current?.pack;
    const targetInfo = target?.manifest || target?.pack;

    return {
      to: {
        ...getLayerBannerInfo(targetInfo),
        helperText: t("Pending Configuration"),
        tooltipText: t(
          "This section represents the configuration that will become the new current, applied configuration. You can either edit it directly, or use the editor to keep customizations from the current configuration on the left."
        ),
      },
      from: {
        ...getLayerBannerInfo(currentInfo),
        helperText: t("Current Configuration"),
        tooltipText: t(
          "This section represents your current configuration. It includes any customizations that you have made to the pack default configuration."
        ),
      },
    };
  }, [t, selectedLayer]);

  function renderEditorTitle() {
    return (
      <TitleBanner>
        {showDiff ? (
          <EditorSplitTitle {...editorInfo} />
        ) : (
          <PackTitle {...editorInfo.to} />
        )}
      </TitleBanner>
    );
  }

  return (
    <Modal
      width="1400px"
      title={t("Review Update Changes")}
      service={modalService}
      footer={null}
    >
      <MainWrap>
        <EditorLayout />
        <SidebarSlot.Source>
          <ProfilesList
            profilesDiffs={profilesDiffs}
            selectedLayer={selectedLayerGuid}
            reviewedLayers={reviewedLayers}
            {...rest}
          />
          {hasVariableChanges && reviewVariables ? (
            <ProfileVariablesLayer onClick={onSelectProfileVariablesLayer}>
              <VariablesLayerTitle>
                <Icon awesome={faBracketsCurly} />
                <span>{t("Profile variables changes")}</span>
              </VariablesLayerTitle>
              <Tooltip
                placement="topRight"
                title={areVariablesReviewed ? t("Reviewed") : t("Not Reviewed")}
              >
                <Icon awesome={areVariablesReviewed ? faCheck : faCircle} />
              </Tooltip>
            </ProfileVariablesLayer>
          ) : null}
        </SidebarSlot.Source>
        <EditorSlot.Source>
          <ValuesForm preventInitOnMount module={valuesForm.name}>
            <Switch value={section}>
              <Switch.Case value={MODAL_SECTIONS.VALUES_FORM}>
                <ConnectedEditor
                  defaultValues={selectedLayer?.current?.values || ""}
                  originalValues={originalValues?.[selectedLayerGuid] || ""}
                  language="yaml"
                  showDiff={showDiff}
                  readOnly={readOnly}
                  {...rest}
                  title={renderEditorTitle()}
                  key={selectedLayerGuid || "none"}
                  name={`values.${selectedLayerGuid}` || "none"}
                />
              </Switch.Case>
              <Switch.Case value={MODAL_SECTIONS.PROFILE_VARIABLES}>
                <ProfileVariablesDiffs
                  diffs={variableChanges}
                  valuesForm={valuesForm}
                  toggleLayers={rest.toggleExpandedVariablesDiffs}
                  {...variablesData}
                />
              </Switch.Case>
            </Switch>
          </ValuesForm>
        </EditorSlot.Source>
        <FooterSlot.Source>
          {isRepaveRequired && (
            <Alert type="warning">
              <WarningIcon awesome={faTriangleExclamation} />
              {cloudType === "edge-native"
                ? t("These pack updates could cause a service disruption.")
                : t(
                    "Repaving might temporarily affect your cluster's performance or configuration."
                  )}{" "}
              <DocLink
                location="full-cluster-repave"
                text={t(
                  "Learn more about repave behavior and configuration changes"
                )}
                fontSize={12}
              />
            </Alert>
          )}
          <FooterWrap>
            <ActionsWrap>
              <TextButton
                data-qa="cancel-action"
                onClick={() => {
                  modalService.reject();
                }}
              >
                {t("Cancel")}
              </TextButton>
              <Button
                data-qa="continue-review"
                loading={isSubmitting}
                onClick={() => modalService.resolve()}
                disabled={disableConfirm}
              >
                {confirmLabel}
              </Button>
              {canUpdateLater && (
                <Button
                  data-qa="update-later"
                  loading={isSubmitting}
                  onClick={() => modalService.resolve({ updateLater: true })}
                  disabled={disableConfirm}
                >
                  {t("Apply Changes Later")}
                </Button>
              )}
            </ActionsWrap>
          </FooterWrap>
        </FooterSlot.Source>
      </MainWrap>
    </Modal>
  );
}

export default ClusterPackDiffsModal;
