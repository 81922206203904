import React from "react";
import { useTheme } from "components/common/Theme";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { edgeMachineDetailsFetcher } from "state/cluster/services/edgemachines";
import styled from "styled-components";
import {
  mediumLightGray,
  sidebarBackground,
  white,
} from "utils/constants/colors";
import Modal from "components/ui/Modal";
import {
  remoteShellFormActions,
  remoteShellModal,
} from "state/cluster/actions/list/edgemachines";
import Button from "components/ui/Button";
import { createForm } from "modules/form";
import Fields from "components/ui/Fields";
import Terminal from "components/common/Terminal";
import i18next from "i18next";
import Alert from "components/common/Alert";

const RemoteShellContainer = styled.div`
  position: fixed;
  inset: 0px;
  background: ${white};
  display: flex;
  flex-direction: column;
  z-index: 3;
`;
const HeaderWrapper = styled.div`
  background-color: ${sidebarBackground};
  color: ${white};
  display: flex;
  justify-content: space-between;
  padding: 16px;
  align-items: center;
`;
const StyledHeader = styled.div`
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.15px;
`;
const HostIdWrapper = styled.div`
  display: flex;
  font-size: 14px;
  letter-spacing: 0.15px;
`;
const HostLabel = styled.div`
  font-weight: 500;
  color: ${mediumLightGray};
`;
const HostId = styled.div`
  margin-left: 64px;
`;
const StyledFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
const FormWrapper = styled.div`
  .ant-input-number {
    width: 100%;
  }
`;
const TerminalWrapper = styled.div`
  height: 100%;
  .terminal.xterm {
    height: 100%;
    padding: 16px;
  }
`;
const StyledAlert = styled(Alert)`
  .ant-alert-description {
    overflow-y: auto;
    max-height: 110px;

    span {
      width: 100%;
    }
  }
`;

export const terminalRef = React.createRef(null);

const Form = ({ isRemoteSshTempUserEnabled }) => {
  const { t } = useTranslation();
  if (isRemoteSshTempUserEnabled) {
    return null;
  }

  return (
    <FormWrapper>
      <form autoComplete="off" onSubmit={(ev) => ev.preventDefault()}>
        <Fields.Input
          data-qa="user"
          name="user"
          required={true}
          placeholder={t("Type here...")}
          label={t("User")}
        />
        <Fields.PasswordField
          data-qa="password"
          name="password"
          required={true}
          placeholder={t("Type here...")}
          label={t("Password")}
        />
      </form>
    </FormWrapper>
  );
};

const ConnectedForm = connect((state) => ({
  isRemoteSshTempUserEnabled:
    state.forms["remoteShell"]?.data?.isRemoteSshTempUserEnabled,
}))(Form);

const RemoteShelForm = createForm({
  Component: ConnectedForm,
  actions: remoteShellFormActions,
});

const modalDetailsMap = {
  inactive: {
    title: () => i18next.t("Login to remote shell"),
    message: () => i18next.t("Connection timed out. Log in again."),
    buttonLabel: () => i18next.t("Confirm"),
    type: "info",
  },
  error: {
    title: () => i18next.t("Error"),
    message: () => i18next.t("Connection failed. Check info and retry."),
    buttonLabel: () => i18next.t("Retry"),
    type: "error",
  },
  initial: {
    title: () => i18next.t("Login to remote shell"),
    buttonLabel: () => i18next.t("Confirm"),
  },
};

function RemoteShell({ edgeHost, isSubmitting, error, reason, errorMessage }) {
  const { t } = useTranslation();
  const { SideMenuLogo } = useTheme({
    isMenuExpanded: true,
    isAdminMode: true,
  });
  const modalDetails = modalDetailsMap[reason] || modalDetailsMap.initial;

  function renderInfoMessage() {
    if (!modalDetails.type) {
      return;
    }
    return (
      <div>
        <StyledAlert
          banner
          type={modalDetails.type}
          message={modalDetails.message?.()}
          description={error || errorMessage}
        />
      </div>
    );
  }

  return (
    <RemoteShellContainer>
      <HeaderWrapper>
        <div>
          <StyledHeader>{t("Remote Shell")}</StyledHeader>
          <HostIdWrapper>
            <HostLabel>{t("Edge Host ID")}</HostLabel>
            <HostId>{edgeHost?.metadata?.uid}</HostId>
          </HostIdWrapper>
        </div>
        <div>
          <SideMenuLogo />
        </div>
      </HeaderWrapper>
      <TerminalWrapper>
        <Terminal terminalRef={terminalRef} />
      </TerminalWrapper>
      <Modal
        style={{ top: "-20%" }}
        width="450px"
        service={remoteShellModal}
        title={modalDetails.title?.()}
        loading={true}
        closable={false}
        footer={
          <StyledFooter>
            <Button
              data-qa="confirm"
              primary
              onClick={() => remoteShellModal.resolve()}
              loading={isSubmitting}
            >
              {!isSubmitting ? modalDetails.buttonLabel?.() : t("Connecting")}
            </Button>
          </StyledFooter>
        }
      >
        {renderInfoMessage()}
        <RemoteShelForm module="remoteShell" preventInitOnMount />
      </Modal>
    </RemoteShellContainer>
  );
}

export default connect(
  (state) => ({
    edgeHost: edgeMachineDetailsFetcher?.selector(state)?.result,
    isSubmitting: state.forms["remoteShell"]?.submitting,
    error: state.forms["remoteShell"]?.data?.error,
    reason: remoteShellModal.data?.reason,
    errorMessage: remoteShellModal.data?.errorMessage,
  }),
  {}
)(RemoteShell);
