export function DIFF_VIEWER_SELECT_LAYER(
  nextState,
  { module, layerGuid, profileGuid }
) {
  if (!nextState[module]) {
    nextState[module] = {};
  }
  nextState[module].selectedLayer = layerGuid;
  nextState[module].selectedProfile = profileGuid;
}

export function DIFF_VIEWER_UPDATE_EXPANDED(
  nextState,
  { module, profileGuids }
) {
  if (!nextState[module]) {
    nextState[module] = {};
  }
  nextState[module].expanded = profileGuids;
}

export function DIFF_VIEWER_SET_DIF_SNAPSHOT(nextState, { module, snapshot }) {
  if (!nextState[module]) {
    nextState[module] = {};
  }
  nextState[module].diffSnapshot = snapshot;
}

export function DIFF_VIEWER_SELECT_VARIABLES_LAYER(
  nextState,
  { module, layerGuid }
) {
  if (!nextState[module]) {
    nextState[module] = {};
  }
  nextState[module].selectedLayer = layerGuid;
}

export function DIFF_VIEWER_VARIABLES_UPDATE_EXPANDED(
  nextState,
  { module, profileGuids },
  state
) {
  if (!nextState[module]) {
    nextState[module] = {};
  }

  const alreadyReviewed = state[module].reviewedVariableLayers || [];
  const newlyReviewed = profileGuids.reduce((accumulator, layer) => {
    if (!alreadyReviewed.includes(layer)) {
      accumulator.push(layer);
    }
    return accumulator;
  }, []);

  nextState[module].expandedVariableLayers = profileGuids;
  nextState[module].reviewedVariableLayers = [
    ...alreadyReviewed,
    ...newlyReviewed,
  ];
}

export function RESET_REVIEWED_VARIABLE_LAYERS(nextState, { module }) {
  if (!nextState[module]) {
    nextState[module] = {};
  }
  nextState[module].reviewedVariableLayers = [];
  nextState[module].expandedVariableLayers = [];
}
