import React, { useMemo } from "react";
import { ProfileBuilderContext } from "modules/profileBuilder/services";
import { useContext } from "react";
import styled, { css } from "styled-components";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import ActionMenu from "components/common/ActionMenu";
import {
  borderDefault,
  elevationSurfaceSubtlest,
  lightNeutralNeutral1000,
  red,
  backgroundBrandBold,
  backgroundAccentNeutralSubtle,
  textSubtle,
  textInverse,
  lightCyan,
  primaryBlue,
} from "utils/constants/colors";
import { Tooltip } from "antd";
import Button from "components/ui/Button";
import { Trans, useTranslation } from "react-i18next";
import EmptyResults from "components/ui/EmptyResults";
import DocLink from "components/common/DocLink";
import { faBracketsCurly } from "@fortawesome/pro-regular-svg-icons";
import Icon from "components/ui/Icon";
import Clipboard from "components/common/Clipboard";
import Alert from "components/common/Alert";
import { useSelector } from "react-redux";
import { faGripDotsVertical } from "@fortawesome/pro-light-svg-icons";
import { faCircleInfo } from "@fortawesome/pro-regular-svg-icons";
import InfoCard from "components/ui/InfoCard/InfoCard";

const VariableWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  border-radius: 4px;
  border: 1px solid ${borderDefault};
  background-color: ${elevationSurfaceSubtlest};
  font-size: 12px;
  line-height: 16px;
`;

const VariableUsage = styled.div`
  color: ${textSubtle};
  background-color: ${backgroundAccentNeutralSubtle};
  padding: 2px 6px;
  line-height: 16px;
  border-radius: 10px;
  ${(props) =>
    props.inUse &&
    css`
      background-color: ${backgroundBrandBold};
      color: ${textInverse};
    `}
`;

const VariablesWrap = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  ${Button} {
    width: 100%;
  }
`;

const DragAndDropArea = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  margin: 8px 0;

  > * + * {
    margin-top: 8px;
  }
`;

const VariableName = styled.div`
  font-size: 12px;
  font-weight: 600;
  margin-right: 5px;
  color: ${lightNeutralNeutral1000};
`;

const VariablePartWrap = styled.div`
  display: flex;
  align-items: center;
`;

const InfoCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const StyledAlert = styled(Alert)`
  margin: -24px;
  padding: 8px 16px;
  border: none;
  align-items: center;
`;

const Variable = React.memo(function VariablePrimitive({
  variable,
  variableTotalUsage,
  draggable,
}) {
  const { t } = useTranslation();
  const context = useContext(ProfileBuilderContext);
  const inUse = context.state.isClusterProfileUsed;
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: variable.guid });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  function renderVariableName() {
    const variableName = (
      <VariableName>
        {"{{.spectro.var."}
        {variable.name}
        {"}}"}
      </VariableName>
    );
    if (draggable) {
      return variableName;
    }
    return (
      <Clipboard text={`{{.spectro.var.${variable.name}}}`}>
        <Tooltip
          title={
            !draggable ? (
              <Trans>
                Display Name: {variable.displayName}
                <p>Description: {variable.description}</p>
              </Trans>
            ) : null
          }
        >
          {variableName}
        </Tooltip>
      </Clipboard>
    );
  }

  function renderUsageBreakdown() {
    if (!variableTotalUsage[variable.name]) {
      return null;
    }
    return (
      <>
        {t("Usage breakdown")}
        {(variable.usedIn || []).map((usage) => (
          <div key={usage.name}>
            {usage.parentName ? `${usage.parentName}/` : ""}
            {usage.name}: {usage.occurrences}
          </div>
        ))}
      </>
    );
  }

  function renderActions() {
    if (draggable) {
      return null;
    }
    return (
      <ActionMenu
        options={[
          {
            label: inUse ? t("View") : t("Edit"),
            onClick: () => context?.actions?.editVariable(variable),
          },
          inUse || variableTotalUsage[variable.name]
            ? null
            : {
                label: t("Delete"),
                color: red,
                onClick: () => context?.actions?.deleteVariable(variable),
              },
        ].filter(Boolean)}
      />
    );
  }

  return (
    <div ref={setNodeRef} style={style} {...attributes}>
      <VariableWrap>
        <VariablePartWrap>
          {draggable ? (
            <div {...listeners}>
              <Icon
                awesome={faGripDotsVertical}
                style={{ cursor: "grab", marginRight: "8px" }}
              />
            </div>
          ) : null}
          {renderVariableName()}
        </VariablePartWrap>
        <VariablePartWrap>
          <Tooltip title={renderUsageBreakdown()}>
            <VariableUsage inUse={!!variableTotalUsage[variable.name]}>
              {!!variableTotalUsage[variable.name]
                ? `${variableTotalUsage[variable.name]} layers`
                : "unused"}
            </VariableUsage>
          </Tooltip>
        </VariablePartWrap>
        {renderActions()}
      </VariableWrap>
    </div>
  );
});

export const InfoBanner = () => {
  const { t } = useTranslation();
  return (
    <StyledAlert
      description={t(
        "Variables are profile values and are available across packs"
      )}
      type="info"
      showIcon
    />
  );
};

export const EmptyVariables = ({ actions = null }) => {
  const { t } = useTranslation();
  return (
    <>
      <InfoCard
        description={t(
          "Cluster profile variables enable you to create placeholders for parameters in profile layer configurations, which you can then populate for individual clusters during deployment."
        )}
        icon={
          <Icon
            awesome={faCircleInfo}
            style={{ color: primaryBlue, fontSize: "24px" }}
          />
        }
        theme={{ backgroundColor: lightCyan }}
      >
        <DocLink
          location="profile-variables"
          text={t("What are profile variables?")}
        />
      </InfoCard>
      <EmptyResults description={<p>{t("No profile variables yet")}</p>}>
        {actions}
      </EmptyResults>
    </>
  );
};

export default function Variables() {
  const context = useContext(ProfileBuilderContext);
  const inUse = context.state.isClusterProfileUsed;
  const { t } = useTranslation();
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );
  const { variablesOrderMode } = context.state;
  let inUseWarning = null;

  const variables = useSelector(context.selectors.getVariables);
  const variableTotalUsage = useMemo(() => {
    return variables.reduce((acc, variable) => {
      acc[variable.name] = variable.usedIn.reduce(
        (usageAcc, { occurrences }) => {
          return usageAcc + occurrences;
        },
        0
      );
      return acc;
    }, {});
  }, [variables]);
  const variableGuids = variables.map((variable) => variable.guid);

  let createButton = (
    <Button
      variant="flat"
      data-qa="create-variable"
      onClick={context.actions.createVariable}
    >
      <Icon awesome={faBracketsCurly} />
      {t("Create variable")}
    </Button>
  );

  if (inUse) {
    inUseWarning = (
      <Alert type="warning">
        <h4>Profile is in use</h4>
        <p>
          Variables cannot be removed, created or edited because this profile is
          used in cluster(s)
        </p>
        <h4>What can I do?</h4>
        <p>
          To edit variables under this profile, you can create a new version for
          it. This allows you to modify profile variables without affecting the
          current configuration.
        </p>
        <DocLink
          text={t("How to version a cluster profile")}
          location="cluster-profile-versioning"
        />
      </Alert>
    );
    createButton = null;
  }

  if (!variables.length) {
    if (inUse) {
      return inUseWarning;
    }

    return (
      <InfoCardWrapper>
        <div>
          <EmptyVariables actions={createButton} />
        </div>
        <InfoBanner />
      </InfoCardWrapper>
    );
  }

  function handleDragEnd(event) {
    const { active, over } = event;
    if (active.id !== over.id) {
      const oldIndex = variables.findIndex(
        (variable) => variable.guid === active.id
      );
      const newIndex = variables.findIndex(
        (variable) => variable.guid === over.id
      );
      context.actions.updateVariablesOrder({
        oldIndex,
        newIndex,
      });
    }
  }

  let confirmOrderButton = null;
  if (variablesOrderMode) {
    createButton = null;
    confirmOrderButton = (
      <Button
        variant="flat"
        data-qa="confirm-order"
        onClick={() => {
          context.actions.confirmVariablesOrder();
        }}
      >
        {t("Confirm order")}
      </Button>
    );
  }

  return (
    <VariablesWrap>
      {createButton}
      {inUseWarning}
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          items={variableGuids}
          strategy={verticalListSortingStrategy}
        >
          <DragAndDropArea>
            {(variables || []).map((variable) => (
              <Variable
                key={variable.guid}
                variable={variable}
                variableTotalUsage={variableTotalUsage}
                draggable={variablesOrderMode}
              />
            ))}
          </DragAndDropArea>
        </SortableContext>
      </DndContext>
      {confirmOrderButton}
      <InfoBanner />
    </VariablesWrap>
  );
}
