import React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import Fields from "components/ui/Fields";

import {
  onArchitectureChange,
  onEdgeHostChange,
} from "state/cluster/actions/create/flows/baremetal";
import { getNicsByDeviceUid } from "state/cluster/selectors/create";
import { ARCHITECTURE_TYPES, APPLIANCES_STATES_MAP } from "utils/constants";
import { createDeviceListingSelection } from "../edge/Devices";
import DocLink from "components/common/DocLink";
import { useFormContext } from "modules/form";

const DevicesWrapper = styled.div`
  margin-top: 12px;
`;

export const DeviceSelection = createDeviceListingSelection({
  name: "cluster-create",
  statusOptions: [APPLIANCES_STATES_MAP.ready, APPLIANCES_STATES_MAP.unpaired],
});
const ConnectedDeviceSelection = connect(
  (state, { poolIndex }) => ({
    nicsByHost: getNicsByDeviceUid(state),
    value: state?.forms?.cluster?.data?.nodePools?.[poolIndex]?.edgeHosts || [],
    devicesInUse: state?.forms?.cluster?.data?.nodePools.reduce(
      (accumulator, pool, index) => {
        if (index === poolIndex) {
          return accumulator;
        }

        return accumulator.concat(pool.edgeHosts.map((host) => host.guid));
      },
      []
    ),
    architecture:
      state?.forms?.cluster?.data?.nodePools?.[poolIndex]?.architecture,
  }),
  (dispatch, { poolIndex }) => ({
    onChange: (value) =>
      dispatch(onEdgeHostChange({ poolIndex, edgeHosts: value })),
  })
)(DeviceSelection.Block);

function EdgeNativeConfigurationFields({
  name,
  index,
  formData,
  onArchitectureChange,
  nodePoolsWrapperRef,
  k8sLayer,
}) {
  const { t } = useTranslation();
  const pool = formData.nodePools?.[index];
  const isMasterPool = pool?.isMaster;
  function getName(subField) {
    const currentPath = name ? `${name}.` : "";
    return `${currentPath}${subField}`;
  }

  const form = useFormContext();

  function onSetTwoNodeCapability(value) {
    form.onFieldChange(getName("isTwoNode"), value);
    form.revalidateField(getName("edgeHosts"));
  }

  const isK3s = (k8sLayer?.name || "").includes("k3s");

  return (
    <>
      <Fields.Select
        name={getName("architecture")}
        label={t("Architecture")}
        data-qa={getName("architecture")}
        options={ARCHITECTURE_TYPES}
        onChange={onArchitectureChange.bind(null, index)}
      />
      {isMasterPool ? (
        <Fields.Switch
          name={getName("isTwoNode")}
          label={t("Enable two-node capability")}
          data-qa={getName("isTwoNode")}
          onChange={onSetTwoNodeCapability}
          disabled={!isK3s}
          description={t("Available only for cluster profiles that use K3s")}
          helperText={
            <>
              {t(
                "This option provides essential redundancy with minimized resource use, ideal for ensuring reliability in smaller setups."
              )}
              <div>
                <DocLink location="two-node-control-plane" />
              </div>
            </>
          }
        />
      ) : null}
      <DevicesWrapper>
        <Fields.Empty
          label={t("Edge Hosts")}
          description={t(
            "You can use a two node pool configuration in order to reduce costs."
          )}
        />
        <ConnectedDeviceSelection
          name={getName("edgeHosts")}
          poolIndex={index}
          formData={formData}
          nodePoolsWrapperRef={nodePoolsWrapperRef}
          isMasterPool={isMasterPool}
          twoNodeEnabled={pool?.isTwoNode}
        />
      </DevicesWrapper>
    </>
  );
}

export default connect(null, { onArchitectureChange })(
  EdgeNativeConfigurationFields
);
