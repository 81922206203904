import React from "react";
import styled, { css } from "styled-components";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import ModalService from "services/modal";

import { red, darkGray } from "utils/constants/colors";

import Table from "components/ui/Table";
import ActionMenu from "components/common/ActionMenu";
import Confirm from "components/common/ConfirmationModal";
import { TextButton } from "components/ui/Button";
import AddNewItem from "components/styled/AddNewItem";
import EmptyResults from "components/ui/EmptyResults";

const RoleBindingsWrapper = styled.div`
  margin-top: 44px;

  ${(props) =>
    props.noMargin &&
    css`
      margin-top: 0;
    `}
`;

export const RoleBindingsTableTitle = styled.div`
  color: ${darkGray};
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.0015em;
  margin-bottom: 10px;
`;

export const StyledAddNewItemButton = styled(TextButton)`
  width: 100%;
  height: 100%;
  padding: 0;
  > div {
    height: 40px;
    margin: 4px 0;
  }
`;

export const removeRoleConfirmationModal = new ModalService();

function RoleBindingsTable({
  onRemove,
  openRoleBindingForm = () => {},
  bindingType = "cluster",
  modalData,
  dataSource,
  canUpdate = true,
}) {
  const { t } = useTranslation();

  function renderActionsColumn(data, _, index) {
    const actions = [
      {
        key: "edit",
        label: t("Edit"),
        onClick: () => openRoleBindingForm(index),
      },
      {
        key: "remove",
        label: t("Remove"),
        color: red,
        onClick: () => onRemove({ index, name: data?.roleName }),
      },
    ].filter(Boolean);
    return <ActionMenu options={actions} />;
  }

  const columns = [
    {
      title:
        bindingType === "cluster" ? t("Cluster Role Name") : t("Role Name"),
      key: "roleName",
      render(data) {
        return data?.roleName;
      },
    },
    bindingType === "namespace" && {
      title: t("Namespace"),
      key: "namespace",
      render(data) {
        return data?.namespace;
      },
    },
    {
      title: t("Subjects"),
      key: "subjects",
      render(data) {
        return data?.subjects?.length || 0;
      },
    },
    canUpdate && {
      title: null,
      key: "actions",
      render: renderActionsColumn,
    },
  ].filter(Boolean);

  return (
    <>
      <Table
        columns={columns}
        dataSource={dataSource || []}
        pagination={false}
        locale={{
          emptyText: <EmptyResults />,
        }}
      />

      <StyledAddNewItemButton
        disabled={!canUpdate}
        onClick={() => openRoleBindingForm()}
      >
        <AddNewItem>{t("Add New Binding")}</AddNewItem>
      </StyledAddNewItemButton>

      <Confirm type="error" service={removeRoleConfirmationModal}>
        <Confirm.Question>
          {t(`Are you sure you want to remove {{roleName}} role?`, {
            roleName: modalData?.name,
          })}
        </Confirm.Question>
      </Confirm>
    </>
  );
}

function RoleBindings({ title, noMargin, ...rest }) {
  return (
    <RoleBindingsWrapper noMargin={noMargin}>
      {title && <RoleBindingsTableTitle>{title}</RoleBindingsTableTitle>}
      <RoleBindingsTable {...rest} />
    </RoleBindingsWrapper>
  );
}

export default connect(
  () => ({
    modalData: removeRoleConfirmationModal.data,
  }),
  (dispatch, ownProps) => {
    const { module, formActions, modalService } = ownProps;

    function openRoleBindingForm(index) {
      modalService.open({ index }).then(() => {
        return dispatch(
          formActions.submit({
            module,
          })
        );
      });
      dispatch(formActions.init({ module }));
    }

    return {
      openRoleBindingForm,
    };
  }
)(RoleBindings);
