import { createSelector } from "reselect";
import createApi from "./api/create";
import isEmpty from "lodash/isEmpty";

const api = createApi();
class ApplicationEnvironment {
  _env = {};
  _cloudTypes = [];
  _featureFlags = [];
  _systemTheme = {};

  _cloudsByTypeSelector = createSelector(() =>
    this._cloudTypes.reduce((accumulator, cloudType) => {
      accumulator[cloudType.name] = cloudType;
      return accumulator;
    }, {})
  );

  isCustomCloud(env) {
    const cloudsByType = this._cloudsByTypeSelector();
    return cloudsByType?.[env]?.isCustom;
  }

  async loadEnv() {
    let data = {};
    let versionData = {};

    try {
      data = await api.get(`app.json?${Date.now()}`);
    } catch (err) {}

    if (!data.PALETTE_VERSION) {
      try {
        versionData = await api.get(`v1/mgmt/app/version`);
        data.PALETTE_VERSION = versionData.version.latestVersion;
      } catch (err) {}
    }

    this._env = data;
  }

  async loadCloudTypes() {
    let cloudTypes = [];
    try {
      const response = await api.get("v1/clouds/cloudTypes");
      cloudTypes = response.cloudTypes.map((cloudType) => {
        return {
          ...cloudType,
          smallLogoPath: `/v1/clouds/cloudTypes/${cloudType.name}/logo`,
          logoPath: `/v1/clouds/cloudTypes/${cloudType.name}/logo`,
        };
      });
    } catch (err) {}

    this._cloudTypes = cloudTypes;
  }

  async loadFeatureFlags() {
    try {
      const response = await api.get("v1/users/system/features");
      this._featureFlags = response.items;
    } catch (err) {}
  }

  async loadSystemTheme() {
    let values = "{}";
    try {
      const response = await api.get("v1/system/themes/active");
      values = response.spec?.values;
    } catch (err) {}
    try {
      this._systemTheme = JSON.parse(values);
    } catch (err) {
      console.warn("Error loading system theme", err);
    }
  }

  initialize() {
    const envLoader = this.loadEnv();
    const cloudTypesLoader = this.loadCloudTypes();
    const featureFlags = this.loadFeatureFlags();
    const systemTheme = this.loadSystemTheme();
    return Promise.allSettled([
      envLoader,
      cloudTypesLoader,
      featureFlags,
      systemTheme,
    ]);
  }

  get cloudTypes() {
    return this._cloudTypes;
  }

  get theme() {
    const isVertex = this._env.theme?.variant === "VERTEX";
    if (!isEmpty(this._systemTheme)) {
      return {
        variant: "system",
        isVertex,
        ...this._systemTheme,
      };
    }

    return {
      ...this._env.theme,
      isVertex,
    };
  }

  get env() {
    return this._env;
  }

  get featureFlags() {
    return this._featureFlags
      .filter((flag) => flag?.spec?.isAllowed)
      .map((flag) => flag.metadata.name);
  }
}

const appEnv = new ApplicationEnvironment();

export default appEnv;
