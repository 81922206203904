import React from "react";
import { useDispatch } from "react-redux";
import styled, { css } from "styled-components";
import { useTranslation } from "react-i18next";
import { faBracketsCurly } from "@fortawesome/pro-solid-svg-icons";

import Accordion from "components/ui/Accordion";
import Icon from "components/ui/Icon";
import Fields from "components/ui/Fields";
import Ellipsis from "components/common/Ellipsis";
import { useFormContext } from "modules/form";

import * as COLORS from "utils/constants/colors";
import {
  PROFILE_TYPE_ICONS,
  PROFILE_VARIABLES_CHANGES_MAPPING,
  REVIEW_STATUS_MAPPING,
} from "utils/constants";
import { getFieldPropsFromVariable } from "utils/variablesHelpers";

const Container = styled.div`
  height: 100%;
  padding: 12px 16px;
  overflow-y: auto;
`;

const StyledAccordion = styled(Accordion)`
  .ant-collapse-item {
    background: ${COLORS.elevationSurfaceSubtlest};
    margin-bottom: 16px;
  }

  .ant-collapse-item > .ant-collapse-header {
    height: 48px;
    background: ${COLORS.elevationSurfaceSubtlest};
    border-bottom: none;
    box-shadow: none;

    font-size: 14px;
    text-transform: none;
    color: ${COLORS.lightNeutralNeutral1000};
  }

  .ant-collapse-item .ant-collapse-content-box {
    padding: 8px;
  }

  .ant-collapse-item.profile-diff-addition,
  .ant-collapse-item.profile-diff-addition > .ant-collapse-header {
    background: ${COLORS.backgroundAccentGreenSubtlest};
  }

  .ant-collapse-item.profile-diff-deletion,
  .ant-collapse-item.profile-diff-deletion > .ant-collapse-header {
    background: ${COLORS.backgroundAccentRedSubtlest};
  }
`;

const AccordionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > div {
    flex-grow: 1;
    width: 33%;
  }
`;

const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  svg {
    font-size: 16px;
    flex-shrink: 0;
  }
`;

const VariablesDiffsWrap = styled.div`
  border-radius: 4px;
  background: ${COLORS.textInverse};
  border: 1px solid ${COLORS.borderColorDefault};

  display: flex;
  flex-direction: column;
`;

const DiffHeader = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${COLORS.borderColorDefault};
  padding: 12px 16px;
`;

const ConfigurationSubTitle = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  gap: 12px;
`;

const DiffsListing = styled.div`
  padding: 12px 16px;
  flex-grow: 1;
`;

const VariableDiffWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;

  & > div {
    flex-grow: 2;
    max-width: 33%;
  }

  & > div:nth-child(2) {
    flex-grow: 1;

    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const DiffTypeIcon = styled(Icon)`
  font-size: 16px;

  ${(props) =>
    props.variant === "deletion" &&
    css`
      color: ${COLORS.backgroundDangerBold};
    `}

  ${(props) =>
    props.variant === "addition" &&
    css`
      color: ${COLORS.backgroundSuccess};
    `}
`;

const PropertyPlaceholderWrap = styled.div`
  width: 100%;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: repeating-linear-gradient(
    -55deg,
    ${COLORS.elevationSurfaceSubtlest},
    ${COLORS.backgroundAccentNeutralSubtle} 4px
  );

  ${(props) =>
    props.variant === "deletion" &&
    css`
      background: repeating-linear-gradient(
        -55deg,
        ${COLORS.elevationSurfaceSubtlest},
        ${COLORS.backgroundAccentRedSubtlest} 4px
      );
    `}
`;

const ReviewStatusWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;

  font-size: 12px;
  line-height: 14px;

  ${(props) =>
    props.variant === "invalid" &&
    css`
      color: ${COLORS.backgroundDangerBold};
    `}

  ${(props) =>
    props.variant === "completed" &&
    css`
      svg {
        color: ${COLORS.backgroundSuccess};
      }
    `}
`;

const ProfileChangeType = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  font-size: 12px;
  line-height: 18px;
  color: ${COLORS.textSubtlest};

  ${(props) =>
    props.variant === "deletion" &&
    css`
      svg {
        color: ${COLORS.backgroundDangerBold};
      }
    `}
  ${(props) =>
    props.variant === "addition" &&
    css`
      svg {
        color: ${COLORS.backgroundSuccess};
      }
    `};
`;

function PropertyPlaceholder({ variant }) {
  const { t } = useTranslation();

  return (
    <PropertyPlaceholderWrap variant={variant}>
      {variant === "addition"
        ? t("No previous property")
        : t("Property was deleted")}
    </PropertyPlaceholderWrap>
  );
}

function VariableDiff({ variableDiff, valuesForm }) {
  const dispatch = useDispatch();
  const { data: formData } = useFormContext();
  const { type, current, target } = variableDiff;
  const variant = PROFILE_VARIABLES_CHANGES_MAPPING?.[type]?.action;

  const renderProperty = ({ variable, readOnly = false }) => {
    if (!variable) {
      return <PropertyPlaceholder variant={variant} />;
    }

    const fieldValue = formData?.variables?.[variable?.guid]?.value;

    return (
      <Fields.Dynamic
        {...getFieldPropsFromVariable({
          prefix: "variables",
          variable,
          fieldValue,
          readOnly,
          onUseDefaultValue: () => {
            valuesForm?.actions &&
              dispatch(
                valuesForm.actions.onChange({
                  module: valuesForm.name,
                  name: `variables.${variable?.guid}.value`,
                  value: variable.defaultValue,
                })
              );
          },
        })}
      />
    );
  };

  return (
    <VariableDiffWrap>
      <div>{renderProperty({ variable: current, readOnly: true })}</div>
      <div>
        <DiffTypeIcon
          awesome={PROFILE_VARIABLES_CHANGES_MAPPING?.[type]?.icon}
          variant={variant}
        />
      </div>
      <div>{renderProperty({ variable: target })}</div>
    </VariableDiffWrap>
  );
}

function ProfileVariablesDiffs({
  diffs = [],
  valuesForm,
  toggleLayers,
  expanded,
  reviewedLayers,
  diffsWithErrors = [],
}) {
  const { t } = useTranslation();

  const renderReviewStatus = ({
    hasErrors = false,
    isLayerReviewed = false,
  }) => {
    const reviewStatusVariant = isLayerReviewed ? "completed" : "default";
    const variant = hasErrors ? "invalid" : reviewStatusVariant;

    return (
      <ReviewStatusWrap variant={variant}>
        <span>{REVIEW_STATUS_MAPPING[variant].label?.()}</span>
        <Icon awesome={REVIEW_STATUS_MAPPING[variant].icon} />
      </ReviewStatusWrap>
    );
  };

  const renderProfileDiff = (diff) => {
    const { type, target, current, variableChanges } = diff;
    const profile = target || current;
    const profileType =
      profile?.spec?.published?.type || profile?.profile?.spec?.published?.type;
    const variant = PROFILE_VARIABLES_CHANGES_MAPPING[type].action;
    const isLayerReviewed = reviewedLayers?.includes(profile.metadata.uid);
    const hasErrors = diffsWithErrors?.includes(profile.metadata.uid);

    return (
      <Accordion.Panel
        className={`profile-diff-${variant}`}
        key={profile.metadata.uid}
        value={profile.metadata.uid}
        header={
          <AccordionHeader>
            <HeaderTitle>
              {PROFILE_TYPE_ICONS?.[profileType]}
              <Ellipsis title={profile?.metadata?.name}>
                {profile?.metadata?.name}
              </Ellipsis>
            </HeaderTitle>
            {type !== "no-change" && (
              <ProfileChangeType variant={variant}>
                <Icon awesome={PROFILE_VARIABLES_CHANGES_MAPPING[type].icon} />
                <span>
                  {PROFILE_VARIABLES_CHANGES_MAPPING[type]?.label?.()}
                </span>
              </ProfileChangeType>
            )}
            {renderReviewStatus({ hasErrors, isLayerReviewed })}
          </AccordionHeader>
        }
      >
        <VariablesDiffsWrap variant={variant}>
          <DiffHeader>
            <ConfigurationSubTitle>
              <Icon awesome={faBracketsCurly} />
              <span>{t("Running configuration")}</span>
            </ConfigurationSubTitle>
            <ConfigurationSubTitle>
              <Icon awesome={faBracketsCurly} />
              <span>{t("New configuration")}</span>
            </ConfigurationSubTitle>
          </DiffHeader>
          <DiffsListing>
            {(variableChanges || []).map((variableChange, index) => (
              <VariableDiff
                key={index}
                variableDiff={variableChange}
                valuesForm={valuesForm}
              />
            ))}
          </DiffsListing>
        </VariablesDiffsWrap>
      </Accordion.Panel>
    );
  };

  return (
    <Container>
      <StyledAccordion
        variant="white"
        activeKey={expanded}
        onChange={toggleLayers}
      >
        {diffs.map(renderProfileDiff)}
      </StyledAccordion>
    </Container>
  );
}

export default ProfileVariablesDiffs;
