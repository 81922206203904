import React, { useRef } from "react";
import { Terminal as Xterm } from "@xterm/xterm";
import { FitAddon } from "xterm-addon-fit";
import styled from "styled-components";
import "@xterm/xterm/css/xterm.css";

const StyledTerminal = styled.div`
  width: 100%;
  height: 100%;
`;

export default function Terminal({ terminalRef }) {
  const instance = useRef(new Xterm({ cursorBlink: true }));
  const fitAddon = useRef(new FitAddon());
  React.useEffect(() => {
    if (!terminalRef.current) {
      return;
    }

    instance.current.open(terminalRef.current);
    instance.current.loadAddon(fitAddon.current);
    fitAddon.current.fit();

    terminalRef.current.terminal = instance.current;
    terminalRef.current.dimensions = fitAddon.current.proposeDimensions();
  }, [terminalRef]);

  return <StyledTerminal ref={terminalRef} />;
}
