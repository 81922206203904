import i18next from "i18next";
import i18n from "i18next";
import { generatePath } from "react-router";

import { WizardActions } from "modules/wizard/actions";
import createFormActions from "modules/form/actions";
import ListActions from "modules/list/actions";

import api, { nonProjectApi } from "services/api";
import Validator from "services/validator";
import store from "services/store";
import historyService from "services/history";
import notifications from "services/notifications";
import {
  Missing,
  DebouncedRule,
  isKubernetesName,
  MinLength,
  MaxLength,
  areValidKubernetesTags,
  isBelowLimit,
} from "services/validator/rules";

import {
  cancelCreateVirtualClusterModal,
  getVirtualYamlConfig,
} from "../../services/create";
import {
  getSelectedProfiles,
  isProfileStackInitialized,
} from "../../selectors/create";

import { extractQuotasFormUpdates, getQuotasYamlOverrides } from "utils/yaml";
import { VIRTUAL_CLUSTER_CREATION_STEPS } from "utils/constants";
import { CLUSTERS, CLUSTER_GROUPS } from "utils/constants/routes";
import {
  ClusterGroupSchema,
  ClusterProfileSchema,
  ClusterProfileTemplateSchema,
} from "utils/schemas";
import {
  profileModule,
  revertPackValuesConfirm,
} from "state/nestedclusters/services/create";
import { getVirtualClusterPayload } from "state/nestedclusters/selectors/create";
import {
  getClusterGroupEntity,
  getClusterGroupProfileTemplate,
} from "state/clustergroups/selectors/details";
import { getQuotaValue } from "utils/presenters";
import { clusterGroupsFetcherWithProjectId } from "state/clustergroups/services/listing";
import { sandboxClusterQuotaUsageFetcher } from "state/devnestedcluster/services";
import { getVirtualClusterDefaultLimit } from "state/appdeployments/actions/create";
import {
  fetchClusterGroup,
  fetchResolvedValues,
} from "state/clustergroups/actions/details";

export const WIZARD_MODULE = "virtualCluster";
export const VIRTUAL_CLUSTER_FORM_MODULE = "virtualCluster";

export const validator = new Validator();
const validateClusterName = async (value) => {
  let error;

  const promise = api.get(`v1/spectroclusters/validate/name?name=${value}`);

  try {
    await promise;
  } catch (err) {
    let message;
    if (err.code === "InvalidName") {
      message = i18n.t(
        `Spectrocluster name must consist of lower case alphanumeric characters and must start with an alphabet`
      );
    }

    if (err.code === "ResourceAlreadyExists") {
      message = i18n.t(
        `A spectrocluster with the name "{{ value }}" already exists`,
        {
          value,
        }
      );
    }

    error = {
      result: message,
      field: "name",
    };
  }

  return error || false;
};

const nameValidationRule = DebouncedRule()(validateClusterName);

validator.addRule(["name"], Missing());
validator.addRule(["name"], (value) => {
  if (!value || value.length < 3 || value.length > 32) {
    return;
  }
  const promise = nameValidationRule(value);
  store.dispatch({
    type: "VIRTUAL_CLUSTER_NAME_VALIDATION",
    promise,
  });
  return promise;
});
validator.addRule(["name"], isKubernetesName());
validator.addRule(["name"], MaxLength(32));
validator.addRule(["name"], MinLength(3));
validator.addRule(["tags"], areValidKubernetesTags({ onlySpectroTags: true }));
validator.addRule(["clusterGroup"], Missing());

validator.addRule(["storageLimits"], Missing());
validator.addRule(["cpuLimits"], isBelowLimit("CPU", 3));
validator.addRule(["memoryLimits"], isBelowLimit("Memory", 3));

function getReturnPath() {
  const state = store.getState();
  const { location } = state.router;
  const clusterGroupId = state.location?.params?.id;

  if (location.pathname.includes("clustergroups")) {
    return generatePath(CLUSTER_GROUPS.DETAILS_OVERVIEW, {
      tab: "virtual-clusters",
      id: clusterGroupId,
    });
  }

  return generatePath(CLUSTERS.ROOT, {
    tab: "virtual",
  });
}

export const virtualClusterFormActions = createFormActions({
  validator,
  async init() {
    await store.dispatch(clusterGroupsFetcherWithProjectId.fetch());
    const location = store.getState().location;
    const clusterGroup = getClusterGroupEntity(store.getState());
    const limitConfig = clusterGroup?.spec?.clustersConfig?.limitConfig;

    return Promise.resolve({
      clusterGroupUid:
        location.route === "CLUSTER_GROUPS.CREATE_VIRTUAL_CLUSTER"
          ? store.getState().location.params.id
          : "",

      // basic info
      clusterGroup: null,
      name: "",
      description: "",
      tags: [],

      // profile
      clusterProfiles: [],

      // config
      externalTrafficPolicy: "Cluster",
      externalIPs: [],
      loadBalancerSourceRanges: [],
      cpuLimits:
        !!limitConfig && getQuotaValue(limitConfig.cpuMilliCore / 1000, 6),
      memoryLimits:
        !!limitConfig && getQuotaValue(limitConfig.memoryMiB / 1024, 8),
      storageLimits: !!limitConfig && getQuotaValue(limitConfig.storageGiB, 10),
    });
  },

  async submit(data) {
    const payload = getVirtualClusterPayload(store.getState());
    const returnPath = getReturnPath();
    const promise = api.post("v1/spectroclusters/virtual", payload);

    try {
      await promise;
    } catch (error) {
      notifications.error({
        message: i18n.t("Failed to create virtual cluster"),
        description: error.message,
      });
      return;
    }

    notifications.success({
      message: `Virtual cluster "${data.name}" has been created successfully`,
    });
    historyService.push(returnPath);
  },
});

export function onClusterFormSubmit() {
  return async (dispatch) => {
    try {
      await dispatch(
        virtualClusterFormActions.submit({
          module: VIRTUAL_CLUSTER_FORM_MODULE,
        })
      );
    } catch (err) {
      if ((err || []).find((error) => error.field === "layers")) {
        notifications.warn({
          message: i18n.t(
            "There are pack validation errors. Please fix them in order to be able to deploy"
          ),
        });
      }
    }
  };
}

export const wizardActions = new WizardActions({
  formActions: virtualClusterFormActions,
  fieldsToValidate() {
    return {
      0: ["name", "clusterGroup", "cpuLimits", "memoryLimits", "storageLimits"],
    };
  },
  getDescription({ id }) {
    const formData = store.getState().forms[VIRTUAL_CLUSTER_FORM_MODULE]?.data;
    if (id === "basic-info") {
      return formData.name;
    }

    if (id === "profile") {
      return i18next.t("(optional)");
    }

    if (["review", "settings"].includes(id)) {
      return i18next.t("Completed");
    }
  },
  steps: VIRTUAL_CLUSTER_CREATION_STEPS,
  async onStepChange({ id }) {
    const isProfilesInitialized = isProfileStackInitialized(store.getState());
    const { yamlConfigValues } =
      store.getState().forms[VIRTUAL_CLUSTER_FORM_MODULE]?.data || {};

    if (id === "settings" && !yamlConfigValues) {
      store.dispatch(resetConfigToDefaultValues());
    }
    if (id === "profile" && !isProfilesInitialized) {
      store.dispatch({
        type: "PROFILE_STACK_INITIALIZATION",
        value: true,
      });
      store.dispatch(fetchClusterGroupProfiles({ withConfiguration: true }));
    }
  },
});

export function onWizardModalClose() {
  const { name } =
    store.getState().forms[VIRTUAL_CLUSTER_FORM_MODULE]?.data || {};
  const returnPath = getReturnPath();

  if (name === "") {
    historyService.push(returnPath);
    return;
  }

  cancelCreateVirtualClusterModal.open().then(() => {
    store.dispatch({
      type: "PROFILE_STACK_INITIALIZATION",
      value: false,
    });
    historyService.push(returnPath);
  });
}

export function onQuotasFieldChange(name, value) {
  return (dispatch, getState) => {
    dispatch(
      virtualClusterFormActions.onChange({
        module: VIRTUAL_CLUSTER_FORM_MODULE,
        name,
        value,
      })
    );

    const data = getState().forms[VIRTUAL_CLUSTER_FORM_MODULE]?.data || {};

    if (name.includes("allowOverrides")) {
      if (value) {
        dispatch(synchronizeQuotasFormData(data.yamlConfigValues));
      } else {
        dispatch(
          virtualClusterFormActions.clearErrors({
            module: VIRTUAL_CLUSTER_FORM_MODULE,
          })
        );
      }
      return;
    }

    const updatedValues = getQuotasYamlOverrides(data.yamlConfigValues, data);

    dispatch(
      virtualClusterFormActions.onChange({
        module: VIRTUAL_CLUSTER_FORM_MODULE,
        name: "yamlConfigValues",
        value: updatedValues,
      })
    );
  };
}

export const profileSelectorListActions = new ListActions({
  hasPagination: true,
  initialQuery() {
    return { search: "", limit: 20 };
  },
  fetchData(query) {
    const {
      offset,
      limit,
      search,
      sortField,
      sortOrder,
      continue: continueToken,
    } = query;
    const continueQueryParam = continueToken
      ? `&continue=${continueToken}`
      : "";

    const filters = {
      filter: {
        environment: [],
        profileType: ["cluster", "infra"],
        profileName: { contains: search },
      },
      sort: [
        {
          field: sortField || "lastModifiedTimestamp",
          order: sortOrder || "desc",
        },
      ],
    };

    return api.post(
      `v1/dashboard/clusterprofiles?limit=${limit}&offset=${offset}${continueQueryParam}`,
      filters
    );
  },
  schema: [ClusterProfileSchema],
});

export function onClusterProfileChange(name, value) {
  return async (dispatch, getState) => {
    let updatedProfiles;
    const selectedProfiles = getSelectedProfiles(getState());

    const isProfileAlreadyAdded = selectedProfiles.find(
      (profileUid) => profileUid === value
    );

    if (isProfileAlreadyAdded) {
      updatedProfiles = selectedProfiles.filter((profile) => profile !== value);
    } else {
      updatedProfiles = [...selectedProfiles, value];
    }

    dispatch(
      virtualClusterFormActions.onChange({
        name,
        value: updatedProfiles,
        module: VIRTUAL_CLUSTER_FORM_MODULE,
      })
    );
  };
}

function synchronizeQuotasFormData(values) {
  return (dispatch) => {
    const updates = extractQuotasFormUpdates({
      values,
    });

    dispatch(
      virtualClusterFormActions.batchChange({
        module: VIRTUAL_CLUSTER_FORM_MODULE,
        updates,
      })
    );
  };
}

export function resetConfigToDefaultValues() {
  return async (dispatch) => {
    const yamlConfigValues = await getVirtualYamlConfig();
    dispatch(
      virtualClusterFormActions.onChange({
        module: VIRTUAL_CLUSTER_FORM_MODULE,
        name: "yamlConfigValues",
        value: yamlConfigValues,
      })
    );
    dispatch(synchronizeQuotasFormData(yamlConfigValues));
  };
}

export function onEditorRevert() {
  return (dispatch) => {
    revertPackValuesConfirm.open().then(() => {
      dispatch(resetConfigToDefaultValues());
    });
  };
}

export function onEditorValuesChange(value) {
  return (dispatch, getState) => {
    const data = getState().forms?.[VIRTUAL_CLUSTER_FORM_MODULE]?.data || {};
    dispatch(
      virtualClusterFormActions.onChange({
        module: VIRTUAL_CLUSTER_FORM_MODULE,
        name: "yamlConfigValues",
        value,
      })
    );

    if (data.allowOverrides) {
      dispatch(synchronizeQuotasFormData(value));
    }
  };
}

export function initVirtualClusterWizard() {
  return (dispatch) => {
    dispatch({
      type: "PROFILE_STACK_INITIALIZATION",
      value: false,
    });
    dispatch(wizardActions.initialize(WIZARD_MODULE));
    dispatch(
      virtualClusterFormActions.init({ module: VIRTUAL_CLUSTER_FORM_MODULE })
    );
  };
}

export function onClusterGroupChange(clusterGroupUid) {
  return async (dispatch, getState) => {
    const clusterGroups =
      clusterGroupsFetcherWithProjectId.selector(getState())?.result?.items ||
      [];
    const selectedClusterGroupScope = clusterGroups.find(
      (clusterGroup) => clusterGroup.uid === clusterGroupUid
    )?.scope;

    await dispatch(
      sandboxClusterQuotaUsageFetcher.fetch(selectedClusterGroupScope)
    );

    dispatch(
      virtualClusterFormActions.onChange({
        module: VIRTUAL_CLUSTER_FORM_MODULE,
        value: clusterGroupUid,
        name: "clusterGroupUid",
      })
    );

    dispatch(
      virtualClusterFormActions.onChange({
        module: VIRTUAL_CLUSTER_FORM_MODULE,
        value: clusterGroupUid,
        name: "clusterGroup",
      })
    );

    const defaultLimits = await getVirtualClusterDefaultLimit(
      clusterGroupUid,
      selectedClusterGroupScope
    );

    dispatch(
      virtualClusterFormActions.batchChange({
        module: VIRTUAL_CLUSTER_FORM_MODULE,
        updates: defaultLimits,
      })
    );
  };
}

function fetchClusterGroupProfiles({ withConfiguration = false } = {}) {
  return async function (dispatch, getState) {
    dispatch({ type: "FETCH_CLUSTER_PROFILE_INITIALIZE" });
    const clusterGroupUid =
      getState().forms[WIZARD_MODULE]?.data?.clusterGroupUid;
    const isClusterGroupProjectScoped =
      getState().fetcher?.clusterGroups?.result?.items?.find(
        (clusterGroup) => clusterGroup.uid === clusterGroupUid
      )?.scope === "project";

    dispatch(fetchClusterGroup(clusterGroupUid, isClusterGroupProjectScoped));

    const getClusterGroup = isClusterGroupProjectScoped
      ? api.get(`v1/clustergroups/${clusterGroupUid}`)
      : nonProjectApi.get(`v1/clustergroups/${clusterGroupUid}`);
    const getClusterGroupPromise = getClusterGroup.then((data) => ({
      metadata: {
        uid: data.metadata.uid,
      },
      spec: {
        clusterProfileRef: data.spec.clusterProfileRef,
        clusterProfileTemplates: data.spec.clusterProfileTemplates,
      },
    }));

    dispatch({
      promise: getClusterGroupPromise,
      type: "REFRESH_CLUSTER_GROUP_PACKS_SUCCESS",
      schema: ClusterGroupSchema,
    });

    const clusterGroup = await getClusterGroupPromise;

    if (withConfiguration) {
      const resolvedValues = await dispatch(
        fetchResolvedValues(
          clusterGroup.metadata.uid,
          isClusterGroupProjectScoped
        )
      );
      profileModule.actions.initialize({ profiles: [] });
      profileModule.actions.updateResolvedValues(resolvedValues);
    }

    const profilesPromise = isClusterGroupProjectScoped
      ? api.get(
          `v1/clustergroups/${clusterGroup.metadata.uid}/profiles?includePackMeta=schema,presets`
        )
      : nonProjectApi.get(
          `v1/clustergroups/${clusterGroup.metadata.uid}/profiles?includePackMeta=schema,presets`
        );

    dispatch({
      promise: profilesPromise,
      type: "FETCH_CLUSTER_GROUP_BUNDLE",
      schema: { profiles: [ClusterProfileTemplateSchema] },
    });

    await profilesPromise;

    let profiles = getClusterGroupProfileTemplate(getState());
    function fetchProfile(uid) {
      const promise = api.get(`v1/clusterprofiles/${uid}`);
      store.dispatch({
        type: "PROFILE_STACK.FETCH_PROFILE",
        promise,
        schema: ClusterProfileSchema,
      });
      return promise;
    }

    if (withConfiguration) {
      await Promise.allSettled(
        profiles.map(async (profile) => {
          return fetchProfile(profile.metadata.uid);
        })
      );
      profiles = getClusterGroupProfileTemplate(getState());
    }

    profileModule.actions.initialize({
      profiles,
      options: {
        cloudType: clusterGroup.spec.cloudType,
        editMode: true,
        allowInfraProfileRemoval: false,
      },
    });

    dispatch({ type: "FETCH_CLUSTER_GROUP_PROFILE_INITIALIZE_SUCCESS" });

    const redirectSelectedPack = getState().location.params.layerUid;
    if (redirectSelectedPack) {
      profiles.forEach((profile) => {
        const foundPack = profile.spec?.published?.packs.find(
          (pack) => pack?.metadata.uid === redirectSelectedPack
        );

        if (!foundPack) {
          return;
        }

        if (foundPack.spec.type === "manifest") {
          profileModule.actions.onManifestSelect({
            manifestGuid: foundPack.manifests?.[0].guid,
            layerGuid: foundPack.guid,
            profileGuid: profile.guid,
            editMode: true,
            isAttachedManifest: false,
          });
          return;
        }

        profileModule.actions.selectLayer(foundPack.guid);

        return;
      });
    }
  };
}
