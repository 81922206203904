import ConfirmationModal from "components/common/ConfirmationModal";
import { createForm } from "modules/form";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  editHostPairingKeyModal,
  EDIT_HOST_PAIRING_KEY,
  edgeMachineDetailsFetcher,
} from "state/cluster/services/edgemachines";
import Fields from "components/ui/Fields";
import {
  editPairingKeyActions,
  onRemoteShellToggle,
} from "state/cluster/actions/list/edgemachines";
import DocLink from "components/common/DocLink";
import permissions from "services/permissions";
import { coerce, gte } from "semver";

const MIN_STYLUS_VERSION = "4.6.6";

export function isRemoteShellAllowed(agentVersion = "") {
  const cleanVersion = coerce(agentVersion)?.version;
  if (!cleanVersion) {
    return;
  }

  return gte(cleanVersion, MIN_STYLUS_VERSION);
}

const EditPairingKeyForm = createForm({
  actions: editPairingKeyActions,
  Component: connect(
    (state) => ({
      remoteSsh: state.forms[EDIT_HOST_PAIRING_KEY]?.data?.remoteSsh,
      edgeHost: edgeMachineDetailsFetcher?.selector(state)?.result,
    }),
    {
      onRemoteShellToggle,
    }
  )(function Form({ remoteSsh, onRemoteShellToggle, edgeHost = {} }) {
    const { t } = useTranslation();
    const agentVersion = edgeHost?.status?.health?.agentVersion;

    function renderTemporaryAuthSwitch() {
      if (!remoteSsh) {
        return;
      }

      return (
        <Fields.Switch
          name="remoteSshTempUser"
          data-qa="remoteSshTempUser"
          label={t("Create temporary username and password")}
          required={false}
          disabled={!permissions.has("edgehost.sshUserUpdate")}
          helperText={
            <>
              <Trans>
                Enable the creation of a temporary user on the edge host with
                sudo privileges for SSH access from Palette. These credentials
                will be embedded in the SSH connection string for auto login,
                and the temporary user is deleted upon deactivation.
              </Trans>
              <div>
                <DocLink
                  location="remote-shell"
                  text={t("Learn more")}
                  fontSize={12}
                />
              </div>
            </>
          }
        />
      );
    }

    function renderRemoteShellToggles() {
      if (!isRemoteShellAllowed(agentVersion)) {
        return null;
      }

      return (
        <>
          <>
            <Fields.Switch
              name="remoteSsh"
              data-qa="remoteSsh"
              label={t("Remote shell")}
              required={false}
              disabled={!permissions.has("edgehost.sshUpdate")}
              onChange={(value) => onRemoteShellToggle(value)}
              helperText={
                <>
                  <Trans>
                    Activate remote shell access to troubleshoot edge hosts by
                    initiating an SSH connection from Palette using the
                    configured username and password credentials.
                  </Trans>
                  <div>
                    <DocLink
                      location="remote-shell"
                      text={t("Learn more")}
                      fontSize={12}
                    />
                  </div>
                </>
              }
            />
            {renderTemporaryAuthSwitch()}
          </>
        </>
      );
    }

    return (
      <>
        <Fields.Tags
          name="tags"
          data-qa="tags"
          tokenSeparators={[",", " "]}
          label={t("Tags")}
        />
        {renderRemoteShellToggles()}
      </>
    );
  }),
});

export default function EditPairingKeyConfirmationModal() {
  const { t } = useTranslation();
  return (
    <ConfirmationModal
      title={t("Edit Edge Host")}
      service={editHostPairingKeyModal}
    >
      <EditPairingKeyForm module={EDIT_HOST_PAIRING_KEY} />
    </ConfirmationModal>
  );
}
