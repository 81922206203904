import React, { useState, useMemo } from "react";
import { faCheckCircle, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FieldWrap } from "components/styled/Field";
import Button, { TextButton } from "components/ui/Button";
import Icon from "components/ui/Icon";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { midGray, white } from "utils/constants/colors";
import Fields from "components/ui/Fields";
import Drawer from "components/ui/Drawer";
import ModalService from "services/modal";
import ListActions from "modules/list/actions";
import { connect, useDispatch } from "react-redux";
import createList, { Blocks } from "modules/list";
import { EdgeHostFiltersComponent } from "pages/clusters/components/edge/EdgeMachinesFilters";
import { useAppliancesColumns } from "pages/clusters/listing/EdgeMachines";
import { EdgeMachineSchema } from "utils/schemas";
import { parseAppliances } from "utils/parsers";
import api from "services/api";
import { getEntity } from "utils/entities";
import { TagsV2 } from "components/common/Tags";
import AddNewItem from "components/styled/AddNewItem";
import Status from "components/styled/Status/new";
import HealthStatusMessage from "components/styled/HealthStatusMessage";
import { getStoreEntity } from "services/store";
import { FilterBounds } from "pages/clusters/components/edge/EdgeMachinesFilters";
import i18next from "i18next";
import createFormActions from "modules/form/actions";
import { createForm, useFormContext } from "modules/form";
import Switch from "components/common/Switch";
import { FormSection } from "components/styled/Forms";
import Alert from "components/common/Alert";
import { useSelector } from "react-redux";
import { addNodePoolModal } from "state/cluster/services/nodes";
import Validator from "services/validator";
import { isValidDomain } from "services/validator/rules";
import { PopupContainerProvider } from "components/common/Select/PopupContainerProvider";

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  background: #f7f9ff;
  margin-bottom: 16px;
  padding: 16px;
  border-radius: 4px;

  :last-child {
    margin-bottom: 0;
    border-bottom: unset;
  }

  ${TextButton} {
    color: ${midGray};
    width: 48px;
  }
`;

const RowFieldsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  > div {
    width: ${(props) => (props.isVirtualized ? "30%" : "400px")};
    margin-right: 20px;
    margin-bottom: 10px;
  }

  > ${FieldWrap} {
    margin-top: 0;
  }
`;

const Container = styled.div`
  width: 100%;
`;

const CardWrap = styled.div`
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  background: ${white};
  padding: 16px;
`;

const CardTitle = styled.div`
  display: flex;
  justify-content: space-between;
`;

const HeathWrap = styled.div`
  display: flex;
  align-items: center;

  > * + * {
    margin-left: 8px;
  }
`;

const HostActions = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  align-self: flex-start;
`;

function EdgeHostCard({ edgeHost, wrapRef, hostType }) {
  if (!edgeHost) {
    return null;
  }

  return (
    <CardWrap>
      <CardTitle>
        <span>
          {edgeHost.hostUid || edgeHost.metadata?.name} {hostType}
        </span>
        <HeathWrap>
          <Status status={edgeHost?.status?.state} />
          <HealthStatusMessage
            state={edgeHost?.status?.health?.state}
            message={edgeHost?.status?.health?.message}
            compact
          />
        </HeathWrap>
      </CardTitle>
      <TagsV2
        tags={edgeHost.metadata?.labels}
        collisionBoundaryRef={wrapRef?.current}
      />
    </CardWrap>
  );
}

const ConnectedEdgeHostCard = connect((state, ownProps) => ({
  edgeHost: getEntity(() => ownProps.guid, EdgeMachineSchema)(state),
}))(EdgeHostCard);

const getNicNameOptions = (nics = []) => {
  return [
    {
      label: "Auto",
      value: "auto",
      description: i18next.t(
        "Kubernetes API and the rest of its resources will be accessible from any networks attached to the edge host"
      ),
    },
    ...nics.map((nic) => ({
      label: `${nic.nicName} ${nic.ip ? `(${nic.ip})` : ""}`,
      value: nic.nicName,
      description: nic.description || (
        <>
          <div>
            <strong>IP:</strong> {nic.ip || "N/A"}
          </div>
          <div>
            <strong>Gateway:</strong> {nic.gateway || "N/A"}
          </div>
          <div>
            <strong>Subnet:</strong> {nic.subnet || "N/A"}
          </div>
          <div>
            <strong>MAC Address:</strong> {nic.macAddr || "N/A"}
          </div>
          {nic.isDefault ? (
            <div>
              <strong>Is Default: </strong> <Icon awesome={faCheckCircle} />
            </div>
          ) : null}
        </>
      ),
    })),
  ];
};

function ConfigureForm({
  isConfigurable,
  nics,
  isOverlayEnabled,
  hostClusterCloudType,
}) {
  const isDisabled = !isConfigurable && !isOverlayEnabled;
  const { data, onBatchChange } = useFormContext();
  const { t } = useTranslation();
  const hasOwnNics = nics.filter((nic) => nic.value !== "auto").length;
  const isAuto = data.nicName === "auto";
  function onNicChange(nicName) {
    const nic = nics.find((n) => n.nicName === nicName);
    if (nicName === "auto") {
      onBatchChange({
        ...data,
        nicName,
        enableStaticIp: false,
      });
      return;
    }
    if (nic && data.enableStaticIp) {
      onBatchChange({
        ...data,
        nicName,
        ip: nic.ip,
        gateway: nic.gateway,
        subnet: nic.subnet,
        dns: nic.dns,
      });
      return;
    }

    onBatchChange({
      ...data,
      nicName,
    });
  }

  function onStaticIPToggle(isEnabled) {
    if (!isEnabled) {
      onBatchChange({
        ...data,
        enableStaticIp: isEnabled,
        ip: "",
        gateway: "",
        subnet: "",
        dns: [],
      });
      return;
    }

    const nic = nics.find((n) => n.nicName === data.nicName);
    onBatchChange({
      ...data,
      enableStaticIp: isEnabled,
      ip: nic?.ip || "",
      gateway: nic?.gateway || "",
      subnet: nic?.subnet || "",
      dns: nic?.dns || [],
    });
  }

  const fields = {
    hostName: "hostName",
    nicName: "nicName",
    enableStaticIp: "enableStaticIp",
    ip: "ip",
    gateway: "gateway",
    subnet: "subnet",
    dns: "dns",
  };

  return (
    <>
      <Fields.Input
        label={t("Host Name")}
        required={false}
        name={fields.hostName}
        data-qa={fields.hostName}
        disabled={!isConfigurable}
      />
      {!hasOwnNics ? null : (
        <>
          <Fields.Select
            label={t("NIC Name")}
            name={fields.nicName}
            data-qa={fields.nicName}
            options={getNicNameOptions(nics)}
            showSelectedDescription
            onChange={onNicChange}
            disabled={!isConfigurable}
          />
          {isAuto ? null : (
            <>
              <Fields.Switch
                label={t("Static IP")}
                name={fields.enableStaticIp}
                data-qa={fields.enableStaticIp}
                required={false}
                onChange={onStaticIPToggle}
                disabled={isDisabled}
              />
              {data.enableStaticIp ? (
                <>
                  <Fields.Input
                    label={t("IP Address")}
                    required={true}
                    name={fields.ip}
                    data-qa={fields.ip}
                    identifier={fields.ip}
                    disabled={isDisabled}
                  />
                  <Fields.Input
                    label={t("Default gateway")}
                    required={true}
                    name={fields.gateway}
                    data-qa={fields.gateway}
                    identifier={fields.gateway}
                    disabled={isDisabled}
                  />
                  <Fields.Input
                    label={t("Subnet mask")}
                    required={true}
                    name={fields.subnet}
                    data-qa={fields.subnet}
                    identifier={fields.subnet}
                    disabled={isDisabled}
                  />
                  <Fields.Select
                    label={t("DNS Server")}
                    required={true}
                    name={fields.dns}
                    data-qa={fields.dns}
                    identifier={fields.dns}
                    mode="tags"
                    open={false}
                    placeholder={t("comma-separated IPs (e.g: 8.8.8.8)")}
                    tokenSeparators={[",", " "]}
                    helperText={t(
                      "Note: the usage of 8.8.8.8 as an example DNS server is applicable only when there is network access to the public internet. In private environments without internet access, a reachable DNS server must be provided instead."
                    )}
                  />
                </>
              ) : null}
            </>
          )}
        </>
      )}
      <Switch value={hostClusterCloudType}>
        <Switch.Case value="aws">
          <FormSection>{t("Connectivity")}</FormSection>
          <Fields.Input
            label={t("VPN server IP")}
            name="vpnServerIp"
            data-qa="vpnServerIp"
            description={t(
              "Must be a static public IP, not private nor behind NAT"
            )}
          />
        </Switch.Case>
      </Switch>
    </>
  );
}

function DeviceRow({
  index,
  name,
  edgeHost,
  isTwoNode,
  onRemove,
  onEdgeConfigure,
  wrapRef,
  isMasterPool,
  twoNodeEnabled,
}) {
  const { t } = useTranslation();
  function getFieldName(fieldName) {
    const currentPath = name ? `${name}.` : "";
    if (!fieldName) {
      return `${currentPath}${index}`;
    }
    return `${currentPath}${index}.${fieldName}`;
  }

  const healthState = useSelector((state) => {
    const entity = getEntity(() => edgeHost?.guid, EdgeMachineSchema)(state);
    return entity?.status?.health?.state;
  });

  let hostType = "";
  if (isTwoNode) {
    if (index === 0) {
      hostType = t("(Leader)");
    }

    if (index === 1) {
      hostType = t("(Follower)");
    }
  }

  return (
    <Wrapper key={`appliances_${index}`}>
      <Container>
        <RowFieldsWrapper>
          <ConnectedEdgeHostCard
            guid={edgeHost.guid}
            hostType={hostType}
            wrapRef={wrapRef}
          />
        </RowFieldsWrapper>
      </Container>
      <HostActions>
        <Button
          data-qa="delete-device-row"
          onClick={() =>
            onEdgeConfigure({
              index,
              module: getFieldName(),
              hostUid: edgeHost.hostUid,
            })
          }
        >
          {t("Configure")}
        </Button>
        <TextButton
          disabled={
            addNodePoolModal?.data?.type === "edit" &&
            twoNodeEnabled &&
            isMasterPool &&
            healthState?.toLowerCase() === "healthy" &&
            edgeHost?.twoNodeCandidatePriority === "primary"
          }
          data-qa="delete-device-row"
          onClick={() => onRemove(getFieldName())}
        >
          <Icon awesome={faTrashAlt} />
        </TextButton>
      </HostActions>
    </Wrapper>
  );
}

const DevicesWrapper = styled.div`
  padding: 12px 16px;
  border-radius: 4px;
  border: 1px solid #dee1ea;
  background: ${white};
`;

function Devices({
  name,
  value = [],
  onChange,
  isMasterPool,
  onOpen,
  nodePoolsWrapperRef,
  onEdgeConfigure,
  twoNodeEnabled,
}) {
  const { t } = useTranslation();

  return (
    <DevicesWrapper>
      {value.map((edgehost, index) => (
        <DeviceRow
          key={index}
          index={index}
          name={name}
          edgeHost={edgehost}
          isMasterPool={isMasterPool}
          onEdgeConfigure={onEdgeConfigure}
          value={value}
          twoNodeEnabled={twoNodeEnabled}
          isTwoNode={isMasterPool && value.length === 2}
          onRemove={() => {
            const updatedValue = [...value];
            updatedValue.splice(index, 1);
            onChange?.(updatedValue);
          }}
          onChange={(updatedHost) => {
            const updatedValue = [...value];
            updatedValue[index] = updatedHost;
            onChange?.(updatedValue);
          }}
          wrapRef={nodePoolsWrapperRef}
        />
      ))}
      <Fields.Error name={name} />
      <AddNewItem data-qa="add-edge-hosts" onClick={onOpen}>
        {t("Add edge hosts")}
      </AddNewItem>
    </DevicesWrapper>
  );
}
function getFilterPayload(filters = []) {
  return {
    conjunction: "and",
    filters: [
      {
        property: "unused",
        type: "bool",
        condition: {
          bool: {
            value: false,
          },
        },
      },
      // duplicated
      ...filters.map(({ values, property, operator }) => ({
        property,
        type: "string",
        condition: {
          string: {
            operator,
            negation: false,
            match: {
              conjunction: property === "tags" ? "and" : "or",
              values,
            },
            ignoreCase: false,
          },
        },
      })),
    ],
  };
}

const NegatePadding = styled.div`
  margin: -24px;
`;

const StyledAlert = styled(Alert)`
  * + & {
    margin-top: 12px;
  }

  & + * {
    margin-bottom: 12px;
  }
`;

export function createDeviceListingSelection({ name, statusOptions }) {
  const moduleName = `${name}-device-selection`;
  const modalService = new ModalService(moduleName);
  const configureModalService = new ModalService(`${moduleName}-configure`);

  const listingActions = new ListActions({
    schema: [EdgeMachineSchema],
    hasPagination: true,
    persistFilters: true,
    defaultQuery: {
      search: "",
      limit: 25,
      states: [],
      tags: [],
      architecture: "",
    },
    initialQuery() {
      return {
        limit: 25,
      };
    },
    async fetchData(query) {
      const {
        offset,
        limit,
        continue: continueToken,
        states,
        search,
        architecture,
        tags,
      } = query;
      const apiUrl = "v1/dashboard/edgehosts/search";
      const edgeHostStates = states || [];
      const filters = [];

      if (search) {
        filters.push({
          property: "name",
          values: [search],
          operator: "contains",
        });
      }

      if (edgeHostStates.length) {
        filters.push({
          property: "state",
          values: edgeHostStates,
          operator: "eq",
        });
      }

      if (architecture) {
        filters.push({
          property: "architecture",
          values: [architecture],
          operator: "eq",
        });
      }

      if (tags) {
        filters.push({
          property: "tags",
          values: tags.map((tag) => {
            return tag.includes(":")
              ? tag
                  .split(":")
                  ?.map((t) => t?.trim())
                  ?.join(":")
              : tag;
          }),
          operator: "eq",
        });
      }

      const payload = {
        filter: {
          conjuction: "and",
          filterGroups: [getFilterPayload(filters)],
        },
        sort: [{ field: "lastModifiedTimestamp", order: "desc" }],
      };

      const continueQueryParam = continueToken
        ? `&continue=${continueToken}`
        : "";

      const data = await api.post(
        `${apiUrl}?limit=${limit}&offset=${offset || 0}${continueQueryParam}`,
        payload
      );

      return {
        ...data,
        items: parseAppliances(data?.items || []),
      };
    },
  });

  const ListingModule = createList({
    schema: [EdgeMachineSchema],
    actions: listingActions,
    hasPagination: true,
  });

  const additionalColumnConfigs = {
    machineId: {
      columnState: { locked: false },
    },
  };

  function EdgeHostsTable({
    devicesInUse = [],
    selected,
    onSelect,
    twoNodeEnabled = false,
    isMasterPool = false,
  }) {
    const columns = useAppliancesColumns({
      showActions: false,
      additionalColumnConfigs,
    });

    const { height } = FilterBounds.useBounds() || {};
    const offsetHeader = useMemo(() => {
      return height - 24;
    }, [height]);

    return (
      <Blocks.Table
        showColumnManager={true}
        data-qa="edge-hosts-table"
        columns={columns}
        scroll={{ x: "1300px" }}
        thOverflow={true}
        resizable={true}
        sticky={{ offsetHeader }}
        rowSelection={{
          type: "checkbox",
          preserveSelectedRowKeys: true,
          selectedRowKeys: selected.map((item) => item.guid),
          onChange: (selectedRowKeys) => {
            onSelect?.(getStoreEntity(selectedRowKeys, [EdgeMachineSchema]));
          },
          getCheckboxProps: (record) => {
            const isDeviceInUse = devicesInUse.includes(record.guid);
            const isEditMode = addNodePoolModal?.data?.type === "edit";
            const isHealthyMasterNode =
              isMasterPool &&
              twoNodeEnabled &&
              record?.status?.health?.state?.toLowerCase() === "healthy" &&
              record?.twoNodeCandidatePriority === "primary";
            const isHealthyNodeSelected = selected
              .map((item) => item.guid)
              .includes(record.guid);

            return {
              disabled:
                isDeviceInUse ||
                (isEditMode && isHealthyMasterNode && isHealthyNodeSelected),
              title: isDeviceInUse ? "In use" : "",
              "data-qa": "edge-hosts-selection",
              "data-qa-id": record.guid,
            };
          },
        }}
      />
    );
  }

  let userSelection = [];

  function Block({
    name,
    value,
    onChange,
    devicesInUse = [],
    extraItems = [],
    architecture,
    initialArchitecture,
    nodePoolsWrapperRef,
    edgeHostToConfigure,
    isOverlayEnabled,
    nicsByHost,
    hostClusterCloudType,
    twoNodeEnabled,
    isMasterPool,
    ...rest
  }) {
    const { t } = useTranslation();
    const [selected, setSelected] = useState([]);
    const dispatch = useDispatch();

    const EdgeConfigureForm = useMemo(() => {
      const validator = new Validator();
      validator.addRule(["hostName"], (value, key, data) => {
        return isValidDomain({ require_tld: false })(value);
      });

      const actions = createFormActions({
        validator,
        async init() {
          const index = configureModalService.data?.index;
          return Promise.resolve({
            ...value[index],
          });
        },
        submit(data) {
          const index = configureModalService.data?.index;
          const updatedValue = [...value];
          updatedValue[index] = data;
          onChange?.(updatedValue);
          return data;
        },
      });
      const Form = createForm({
        actions: actions,
        Component({ children }) {
          return children;
        },
      });
      Form.actions = actions;

      return Form;
    }, [value, onChange]);

    async function onOpen() {
      const initialQuery = {
        limit: 25,
        architecture,
      };
      await dispatch(listingActions.initialize(moduleName, initialQuery));
      if (initialArchitecture && initialArchitecture === architecture) {
        dispatch(
          listingActions.addItems({ items: extraItems, module: moduleName })
        );
      }
      setSelected(value);
      userSelection = value;
      modalService.open({ name }).then(() => {
        onChange?.(
          userSelection.map((edgeHost) => {
            const initialHost = value.find(
              (host) => host.guid === edgeHost.guid
            );
            if (initialHost) {
              return {
                ...initialHost,
              };
            }
            return {
              hostUid: edgeHost.hostUid || edgeHost.metadata.uid,
              guid: edgeHost.guid,
              nicName: "auto",
              isConfigurable: true,
            };
          })
        );
      });
    }

    return (
      <>
        <Devices
          {...rest}
          name={name}
          value={value}
          onChange={onChange}
          isMasterPool={isMasterPool}
          twoNodeEnabled={twoNodeEnabled}
          onOpen={onOpen}
          nodePoolsWrapperRef={nodePoolsWrapperRef}
          configureModalService={configureModalService}
          onEdgeConfigure={({ index, module, hostUid }) => {
            configureModalService.open({ index, module, hostUid }).then(() => {
              return dispatch(EdgeConfigureForm.actions.submit({ module }));
            });
            dispatch(EdgeConfigureForm.actions.init({ module }));
          }}
        />
        {twoNodeEnabled && isMasterPool ? (
          <StyledAlert
            type="warning"
            description={t(
              "The 2 node HA capability is enabled. Control plane pool size will be permanently set to 2 nodes for the lifetime of the cluster"
            )}
          />
        ) : null}
        {modalService.data?.name === name ? (
          <Drawer
            title={t("Select edge hosts")}
            service={modalService}
            width="1032px"
            footerStyle={{ zIndex: 4 }}
            headerStyle={{ zIndex: 4 }}
          >
            <ListingModule module={moduleName} preventInitOnMount>
              <FilterBounds.Provider>
                <NegatePadding>
                  <EdgeHostFiltersComponent
                    top="-24px"
                    module={moduleName}
                    statusOptions={statusOptions}
                  />
                  <EdgeHostsTable
                    devicesInUse={devicesInUse}
                    twoNodeEnabled={twoNodeEnabled}
                    isMasterPool={isMasterPool}
                    onSelect={(selection) => {
                      setSelected(selection);
                      userSelection = selection;
                    }}
                    selected={selected}
                  />
                  <Blocks.Pagination
                    bottom="-24px"
                    isSticky={false}
                    pageSizeOptions={["10", "25", "50"]}
                  />
                </NegatePadding>
              </FilterBounds.Provider>
            </ListingModule>
          </Drawer>
        ) : null}
        {edgeHostToConfigure ? (
          <Drawer
            service={configureModalService}
            title={t("Configure edge host")}
          >
            <ConnectedEdgeHostCard guid={edgeHostToConfigure?.guid} />
            <EdgeConfigureForm
              preventInitOnMount
              module={configureModalService.data?.module}
            >
              <PopupContainerProvider enabled={true}>
                <ConfigureForm
                  nics={nicsByHost[edgeHostToConfigure?.hostUid] || []}
                  isOverlayEnabled={isOverlayEnabled}
                  isConfigurable={edgeHostToConfigure?.isConfigurable}
                  hostClusterCloudType={hostClusterCloudType}
                />
              </PopupContainerProvider>
            </EdgeConfigureForm>
          </Drawer>
        ) : null}
      </>
    );
  }

  return {
    moduleName,
    services: {
      modalService,
      listingActions,
    },
    Block: connect((state, ownProps) => ({
      edgeHostToConfigure: ownProps.value.find(
        (host) => host.hostUid === configureModalService.data?.hostUid
      ),
    }))(Block),
  };
}
