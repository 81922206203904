import i18n from "i18next";
import isEmpty from "lodash/isEmpty";
import { generatePath } from "react-router";
import history from "services/history";
import netmask from "netmask";

import Validator from "services/validator";
import isIP from "validator/lib/isIP";

import {
  Missing,
  isKubernetesName,
  MaxLength,
  MinLength,
  DebouncedRule,
  areValidKubernetesTags,
  isValidIPRange,
  areValidIPTags,
  isValidIP,
  ApplyIf,
  isValidNamespaceAllocation,
  areValidIPRangesTags,
  isValidRegexNamespace,
  areValidKeyValueK8sLabels,
  validateDomainOrIP,
  isValidTaintKey,
  isValidTaintValue,
} from "services/validator/rules";

import createFormActions from "modules/form/actions";
import { createRoleBindingsFormFactory } from "modules/rolebindings";

import api from "services/api";
import historyService from "services/history";
import notifications from "services/notifications";
import store from "services/store";

import {
  clusterCreateProfileModule,
  eksKmskeysFetcher,
} from "state/cluster/services/create";
import { getAzureAzs } from "state/cluster/selectors/nodes";
import { getCluster } from "state/cluster/selectors/details";
import {
  getSelectedClusterProfile,
  getSelectedCloud,
  getSubnetsForSelectedAz,
  isSupervizedEnvironment,
  getPayload,
  mapAwsAzs,
  getAllEdgeHosts,
} from "state/cluster/selectors/create";

import {
  EXPIRY_PERIODS,
  FOUR_MONTHS_IN_HOURS,
  BAREMETAL_ENVS,
  SPECTRO_TAG,
  MANAGED_TO_PURE_ENVIRONMENT,
  COXEDGE_ENVIRONMENT,
  DEFAULT_EDGE_NATIVE_INTERNAL_CIDR,
} from "utils/constants";
import { CLUSTERS } from "utils/constants/routes";
import { fetchClusterProfile } from "state/clusterprofile/actions/details";
import { getRawClusterProfile } from "state/clusterprofile/selectors/details";
import { createGeocodingFormFactory } from "modules/geocoding";
import { coxNodeValidator, coxClusterValidator } from "./flows/cox";
import { rootVolumeValidator } from "../nodes";
import { isAirgapEnv } from "state/auth/selectors";
import appEnv from "services/app";
import { clearClusterTagsCache } from "../list/clusters";

function StaticPlacementRule(message) {
  return function validation(value, key, data) {
    if (data?.staticPlacement) {
      return Missing(message)(value, key, data);
    }

    return false;
  };
}

function NoStaticPlacementRule(message) {
  return function validation(value, key, data) {
    if (!data?.staticPlacement) {
      return Missing(message)(value, key, data);
    }

    return false;
  };
}

function getFilteredEdgeHosts(getEdgeHosts = () => [], data) {
  const allEdgeHosts = getEdgeHosts(store.getState());
  return allEdgeHosts.filter(({ hostUid }) => hostUid !== data?.hostUid);
}

export function isHostNameInUse(getEdgeHosts = () => []) {
  return function validation(value, key, data) {
    const filteredEdgeHosts = getFilteredEdgeHosts(getEdgeHosts, data);
    const hostNames = filteredEdgeHosts
      .map(({ hostName }) => hostName)
      .filter(Boolean);

    return hostNames.includes(value)
      ? i18n.t("This host name is already in use")
      : false;
  };
}

export function isStaticIpInUse(getEdgeHosts = () => []) {
  return function validation(value, key, data) {
    const filteredEdgeHosts = getFilteredEdgeHosts(getEdgeHosts, data);
    const staticIpOptions = filteredEdgeHosts
      .map(({ staticIP }) => staticIP)
      .filter(Boolean);

    return staticIpOptions.includes(value)
      ? i18n.t("This Static IP is already in use")
      : false;
  };
}

export const nodePoolValidator = new Validator();
export function createNodePoolValidator() {
  nodePoolValidator.removeRules();
  const selectedClusterProfile = getSelectedClusterProfile(store.getState());
  const cloudType = selectedClusterProfile?.spec?.published?.cloudType;
  const isCustom = appEnv.isCustomCloud(cloudType);
  const type = isCustom ? "custom" : cloudType;

  const mandatoryCloudRules = {
    aws: ["poolName", "size", "instanceType", "azs"],
    eks: ["poolName", "instanceType", "maxNodeSize", "minNodeSize", "size"],
    vsphere: ["poolName", "size", "disk", "memory", "cpu"],
    azure: ["poolName", "size", "disk", "instanceType", "storageAccountType"],
    aks: ["poolName", "size", "disk", "instanceType", "storageAccountType"],
    gcp: ["poolName", "size", "instanceType"],
    gke: ["poolName", "size", "instanceType"],
    openstack: ["poolName", "size", "flavor", "azs"],
    maas: ["poolName", "size", "minCPU", "resourcePool", "minMem"],
    edge: ["poolName", "size"],
    "edge-native": ["poolName"],
    libvirt: ["poolName", "size", "cpu", "memory", "disk"],
    tke: [
      "poolName",
      "maxNodeSize",
      "minNodeSize",
      "size",
      "azs",
      "instanceType",
    ],
    [COXEDGE_ENVIRONMENT.apiKey]: ["poolName", "deployments"],
    custom: ["poolName", "size"],
  };

  const rules = mandatoryCloudRules[type] || [];

  const taintsValidator = new Validator();
  taintsValidator.addRule(["key", "effect"], Missing());
  taintsValidator.addRule(["key"], MaxLength(253));
  taintsValidator.addRule(["key"], isValidTaintKey());
  taintsValidator.addRule(["value"], MaxLength(63));
  taintsValidator.addRule(["value"], isValidTaintValue());

  nodePoolValidator.addRule(["taints"], function* (value, key, data) {
    if (data.withTaints || !!data.taints.length) {
      yield taintsValidator;
    }
  });

  nodePoolValidator.addRule(["additionalLabels"], function (value) {
    for (const item of value) {
      const [key, val] = item.split(":");
      if (val === SPECTRO_TAG || !key || !val) {
        return i18n.t(`Label "{{item}}" is invalid`, {
          item,
        });
      }
    }

    const keys = value.reduce((acc, item) => {
      if (item.includes(":")) {
        const [key] = item.split(":");
        acc.push(key);
        return acc;
      }
      acc.push(item);
      return acc;
    }, []);
    const set = new Set(keys);
    return keys.length !== set.size
      ? i18n.t(`Duplicate keys are not allowed`)
      : areValidKeyValueK8sLabels()(value);
  });

  if (cloudType === "aws") {
    nodePoolValidator.addRule("maxPricePercentage", (value, key, data) => {
      if (data.instanceOption === "onSpot") {
        return Missing()(value, key, data);
      }

      return false;
    });
  }

  if (cloudType === "eks") {
    nodePoolValidator.addRule(["disk"], async (value, key, data) => {
      const cluster = store.getState().forms.cluster.data;
      const region = cluster?.region;
      const cloudAccountUid = cluster?.credential;

      if (!data?.amiId) {
        return false;
      }
      try {
        const { sizeGB } = await api.get(
          `v1/clouds/aws/imageIds/${data?.amiId}/volumeSize`,
          {
            region,
            cloudAccountUid,
          }
        );

        return value < sizeGB
          ? "Disk size should be greater than AMI size"
          : false;
      } catch (err) {
        return false;
      }
    });
    nodePoolValidator.addRule(["rootVolume"], rootVolumeValidator);
  }

  if (["azure"].includes(cloudType)) {
    nodePoolValidator.addRule("azs", (value, key, data) => {
      if (data.isMaster || data.isControlPlane) {
        return false;
      }

      if (!getAzureAzs(store.getState()).length) {
        return false;
      }

      return Missing()(value, key, data);
    });
  }

  if (cloudType === "vsphere") {
    const domainValidator = new Validator();
    domainValidator.addRule(
      ["cluster", "datastore", "network"],
      Missing({ message: () => " " })
    );
    domainValidator.addRule(["parentPoolUid"], (...args) => {
      if (store.getState().forms.cluster.data.networkType !== "staticIP") {
        return false;
      }

      return Missing({ message: () => " " })(...args);
    });
    nodePoolValidator.addRule("domains", domainValidator);
  }

  nodePoolValidator.addRule(function* () {
    if (cloudType === COXEDGE_ENVIRONMENT.apiKey) {
      yield coxNodeValidator;
    }
  });

  if (BAREMETAL_ENVS.includes(cloudType)) {
    const edgeHostsValidator = new Validator();
    if (cloudType === "edge-native") {
      edgeHostsValidator.addRule(["hostUid"], Missing());
      edgeHostsValidator.addRule("hostName", isKubernetesName());
      edgeHostsValidator.addRule("hostName", MaxLength(63));
      edgeHostsValidator.addRule(
        ["hostName"],
        isHostNameInUse(getAllEdgeHosts)
      );
      edgeHostsValidator.addRule(
        ["nicName"],
        ApplyIf((value, key, data) => !!data?.nicName, Missing())
      );
      edgeHostsValidator.addRule(
        ["ip", "gateway", "subnet", "dns"],
        ApplyIf((value, key, data) => data.enableStaticIp, Missing())
      );
      edgeHostsValidator.addRule(
        ["ip", "gateway", "subnet"],
        ApplyIf((value, key, data) => data.enableStaticIp, isValidIP())
      );
      edgeHostsValidator.addRule(
        ["ip"],
        ApplyIf(
          (value, key, data) => data.enableStaticIp,
          isStaticIpInUse(getAllEdgeHosts)
        )
      );
      edgeHostsValidator.addRule(
        ["dns"],
        ApplyIf((value, key, data) => data.enableStaticIp, areValidIPTags())
      );
    }

    if (cloudType === "libvirt") {
      edgeHostsValidator.addRule(
        [
          "networkType",
          "networks",
          "dataStoragePool",
          "sourceStoragePool",
          "targetStoragePool",
        ],
        Missing()
      );
    }

    nodePoolValidator.addRule("edgeHosts", edgeHostsValidator);
    nodePoolValidator.addRule(
      "edgeHosts",
      Missing({
        message: () =>
          i18n.t("The node pool should contain at least 1 Edge Host"),
      })
    );
    nodePoolValidator.addRule(
      "edgeHosts",
      ApplyIf(
        (_1, _2, data) => data.isMaster,
        (value, key, data) => {
          const isTwoNodeEdgeEnabled = data.isTwoNode;
          if (isTwoNodeEdgeEnabled) {
            return {
              result:
                value.length !== 2
                  ? i18n.t(
                      "The Control Plane node should contain exactly 2 Edge Hosts"
                    )
                  : false,
            };
          }

          return [1, 3, 5].includes(value.length)
            ? false
            : {
                result: i18n.t(
                  "The Control Plane node should contain 1, 3 or 5 Edge Hosts"
                ),
              };
        }
      )
    );
  }

  nodePoolValidator.addRule(rules, Missing());
  nodePoolValidator.addRule("poolName", (poolName) => {
    const nodePools = store.getState().forms.cluster.data.nodePools;
    const sameNames = nodePools.filter(
      (nodePool) => nodePool.poolName === poolName
    );

    return sameNames.length > 1
      ? i18n.t("Node pool names should be unique")
      : false;
  });

  nodePoolValidator.addRule("size", (size, _, nodePool) => {
    const cloudType = store.getState().forms.cluster.data.cloudType;

    if (cloudType === "edge-native") {
      return false;
    }

    if (nodePool.isMaster && size % 2 === 0) {
      return i18n.t("Control Plane node pool size should be an odd number");
    }

    return false;
  });
  nodePoolValidator.addRule(["poolName"], isKubernetesName());
  nodePoolValidator.addRule(["poolName"], MaxLength(63));
  nodePoolValidator.addRule(["nodePoolConfigMacros"], function* (macrosObj) {
    const { poolIndex, ...rest } = macrosObj;
    const fields = Object.keys(rest).filter((key) => !rest[key].isOptional);

    for (const field of fields) {
      yield {
        result: Missing()(macrosObj[field].value, field, macrosObj),
        field: `nodePools.${poolIndex}.nodePoolConfigMacros.${field}.value`,
      };
    }
  });
}

const awsFieldsValidator = new Validator();
awsFieldsValidator.addRule(["region"], Missing());
awsFieldsValidator.addRule(
  ["ssh"],
  Missing({
    message() {
      const regionName = store.getState().forms.cluster.data.region;
      const staticPlacement =
        store.getState().forms.cluster.data.staticPlacement;
      const isSupervized = isSupervizedEnvironment(store.getState());
      const message = i18n.t(
        "Please import/create a new keypair into your region {{regionName}}.",
        {
          regionName,
        }
      );
      if (!isSupervized) {
        return message;
      }

      if (staticPlacement) {
        return null;
      }

      return message;
    },
  })
);

awsFieldsValidator.addRule(["provider"], (value, key, data) => {
  if (data.cloudType === "eks" && data.enableEncryption) {
    return Missing()(value, key, data);
  }
  return false;
});

awsFieldsValidator.addRule(["provider"], async (value, key, data) => {
  if (data.cloudType === "eks" && data.enableEncryption && value) {
    const kms =
      eksKmskeysFetcher.key(data.region).selector(store.getState())?.result ||
      [];
    const id = kms.find((kms) => kms.keyArn === value)?.keyId;
    if (!id) {
      return false;
    }
    try {
      const response = await api.get(
        `v1/clouds/aws/regions/${data.region}/kms/${id}?cloudAccountUid=${data.credential}`
      );

      return response.enabled ? false : i18n.t("KMS key is not enabled");
    } catch (err) {
      return false;
    }
  }
  return false;
});

const eksControlPlaneValidator = new Validator();
eksControlPlaneValidator.addRule("azs", function (value, key, data) {
  if (value.length < 2) {
    return i18n.t("Please select at least 2 AZs");
  }

  return false;
});

awsFieldsValidator.addRule(
  ["publicAccessCidrs"],
  ApplyIf(
    (value, key, data) => data?.endpointAccess?.includes("public"),
    Missing()
  )
);
awsFieldsValidator.addRule(["publicAccessCidrs"], areValidIPRangesTags());
awsFieldsValidator.addRule(
  ["privateAccessCidrs"],
  ApplyIf(
    (value, key, data) => data?.endpointAccess?.includes("private"),
    areValidIPRangesTags()
  )
);

awsFieldsValidator.addRule("controlPlane", function* (value, key, data) {
  if (data.cloudType === "eks" && data.staticPlacement) {
    yield eksControlPlaneValidator;
  }

  for (const az of data?.controlPlane?.azs || []) {
    yield {
      result:
        data.staticPlacement && !data.controlPlane?.[`subnet_${az}`]?.length
          ? "Missing subnet for selected az"
          : false,
      field: `controlPlane.subnet_${az}`,
    };
  }
});

const vmwareFieldsValidator = new Validator();
vmwareFieldsValidator.addRule(["datacenter", "folder"], Missing());
vmwareFieldsValidator.addRule(
  ["appliance", "vip"],
  ApplyIf((value, key, data) => {
    return data.clusterType === "edge";
  }, Missing())
);

vmwareFieldsValidator.addRule(
  ["vip"],
  ApplyIf((value, key, data) => {
    return data.clusterType === "edge";
  }, isValidIP())
);

const maasFieldsValidator = new Validator();
maasFieldsValidator.addRule(["domain"], Missing());

const azureFieldsValidator = new Validator();
azureFieldsValidator.addRule(
  ["region", "subscriptionId", "resourceGroup", "sshKey"],
  Missing()
);

azureFieldsValidator.addRule(
  [
    "controlPlaneSubnetName",
    "controlPlaneSubnetCidr",
    "controlPlaneSubnetSecurity",
    "controlPlaneSubnetSecurityResourceGroup",
    "workerSubnetName",
    "workerSubnetCidr",
    "workerSubnetSecurity",
    "workerSubnetSecurityResourceGroup",
  ],
  ApplyIf((value, key, data) => {
    return !!(data.hasDisablePropertiesRequest && data.staticPlacement);
  }, Missing())
);

azureFieldsValidator.addRule(
  ["apiServerLBStaticIP"],
  ApplyIf((value, key, data) => {
    return data.apiServerPrivate && data.ipAllocationMethod === "Static";
  }, Missing())
);

azureFieldsValidator.addRule(
  ["apiServerLBStaticIP"],
  ApplyIf((value, key, data) => {
    return data.apiServerPrivate && data.ipAllocationMethod === "Static";
  }, isValidIP())
);

azureFieldsValidator.addRule(
  ["apiServerLBStaticIP"],
  ApplyIf(
    (value, key, data) => {
      return (
        data.apiServerPrivate &&
        data.ipAllocationMethod === "Static" &&
        data.staticPlacement &&
        data.controlPlaneSubnet
      );
    },
    function (value, key, data) {
      const subnet = JSON.parse(data?.controlPlaneSubnet || "");
      const Netmask = netmask.Netmask;
      const block = new Netmask(subnet.cidrBlock);

      if (!(value && isIP(value))) {
        return;
      }

      return !block.contains(value)
        ? i18n.t("The IP must be in the range of {{firstIP}} - {{lastIP}}", {
            firstIP: block.first,
            lastIP: block.last,
          })
        : false;
    }
  )
);

azureFieldsValidator.addRule(
  ["controlPlaneSubnetCidr", "workerSubnetCidr"],
  ApplyIf((value, key, data) => {
    return !!(data.hasDisablePropertiesRequest && data.staticPlacement);
  }, isValidIPRange())
);

azureFieldsValidator.addRule(["vnetResourceGroup"], (value, key, data) => {
  if (!data.staticPlacement) {
    return false;
  }

  return Missing()(value, key, data);
});

azureFieldsValidator.addRule(["vnetName"], (value, key, data) => {
  if (!data.vnetResourceGroup) {
    return false;
  }

  return Missing()(value, key, data);
});

azureFieldsValidator.addRule(["adminGroupObjectIDs"], (value, key, data) => {
  if (!data.hasTenantName) {
    return false;
  }

  if (!data.managedAD) {
    return false;
  }

  return Missing()(value, key, data);
});
azureFieldsValidator.addRule(["vnetCidrBlock"], (value, key, data) => {
  if (!data.vnetName) {
    return false;
  }

  return Missing()(value, key, data);
});

azureFieldsValidator.addRule(["vnetCidrBlock"], (value, key, data) => {
  if (!data.vnetName) {
    return false;
  }

  return isValidIPRange()(value, key, data);
});

const googleFieldsValidator = new Validator();
googleFieldsValidator.addRule(["project", "region"], Missing());
googleFieldsValidator.addRule(["network"], StaticPlacementRule());
googleFieldsValidator.addRule(function* (cluster) {
  const nodePools = cluster?.nodePools;
  const masterPoolIdx = nodePools.findIndex((nodePool) => nodePool.isMaster);

  for (const idx in nodePools) {
    let result;
    if (Number(idx) === masterPoolIdx) {
      result =
        nodePools?.[idx]?.azs?.length > 0
          ? false
          : i18n.t("Missing availability zones");
    } else {
      if (!nodePools[masterPoolIdx]?.azs?.length) {
        result = i18n.t(
          "Please select availability zones from control plane pool"
        );
      } else {
        result =
          nodePools[idx]?.azs?.length > 0
            ? false
            : i18n.t("Missing availability zones");
      }
    }

    yield {
      result,
      field: `nodePools.${idx}.azs`,
    };
  }
});

const validateClusterName = async (value) => {
  let error;

  const promise = api.get(`v1/spectroclusters/validate/name?name=${value}`);

  try {
    await promise;
  } catch (err) {
    let message;
    if (err.code === "InvalidName") {
      message = i18n.t(
        `Spectrocluster name must consist of lower case alphanumeric characters and must start with an alphabet`
      );
    }

    if (err.code === "ResourceAlreadyExists") {
      message = i18n.t(
        `A spectrocluster with the name "{{ value }}" already exists`,
        {
          value,
        }
      );
    }

    error = {
      result: message,
      field: "name",
    };
  }

  return error || false;
};
const gkeFieldsValidator = new Validator();
gkeFieldsValidator.addRule(["project", "region"], Missing());

const openStackFieldsValidator = new Validator();

openStackFieldsValidator.addRule(["nodeCidr"], isValidIPRange());
openStackFieldsValidator.addRule(["dnsNameservers"], areValidIPTags());

openStackFieldsValidator.addRule(
  ["domain", "region", "project", "sshKeyName"],
  Missing()
);

openStackFieldsValidator.addRule(
  ["nodeCidr", "dnsNameservers"],
  NoStaticPlacementRule()
);
openStackFieldsValidator.addRule(["network", "subnet"], StaticPlacementRule());

const libvirtFieldsValidator = new Validator();
libvirtFieldsValidator.addRule(["vip"], Missing());
libvirtFieldsValidator.addRule(["vip"], isValidIP());

const edgeNativeFieldsValidator = new Validator();
edgeNativeFieldsValidator.addRule(
  ["ip"],
  ApplyIf((value, key, data) => !data.enableOverlay, Missing())
);
edgeNativeFieldsValidator.addRule(["ip"], validateDomainOrIP());
edgeNativeFieldsValidator.addRule(
  ["internalCIDR"],
  ApplyIf((value, key, data) => data.enableOverlay, Missing())
);
edgeNativeFieldsValidator.addRule(
  ["internalCIDR"],
  ApplyIf((value, key, data) => data.enableOverlay, isValidIPRange())
);
edgeNativeFieldsValidator.addRule(["nodePools"], function* (value, key, data) {
  const isPrimaryEnabled = value?.[0]?.edgeHosts?.[0]?.primary;
  const workerPools = value.slice(1);
  const isSecondaryEnabled = workerPools.some((pool) =>
    pool?.edgeHosts?.some((host) => host?.secondary)
  );

  if (isPrimaryEnabled && !isSecondaryEnabled) {
    for (const index in value) {
      if (index === 0) {
        continue;
      }

      const edgeHosts = value[index].edgeHosts;
      for (const edgeIndex in edgeHosts) {
        const edgeHost = edgeHosts[edgeIndex];
        yield {
          result: !edgeHost.secondary
            ? i18n.t("Secondary edge host cannot be enabled without primary")
            : false,
          field: `nodePools.${index}.edgeHosts.${edgeIndex}.secondary`,
        };
      }
    }
  }
});

const tencentFieldsValidator = new Validator();
tencentFieldsValidator.addRule(["credential", "region"], Missing());
tencentFieldsValidator.addRule(["sshKeys"], Missing());
tencentFieldsValidator.addRule(["endpointAccess"], Missing());
tencentFieldsValidator.addRule(
  ["publicSecurityGroup"],
  ApplyIf((value, key, data) => {
    return data.endpointAccess !== "private";
  }, Missing())
);
tencentFieldsValidator.addRule(
  ["privateSubnet"],
  ApplyIf((value, key, data) => {
    return data.endpointAccess !== "public";
  }, Missing())
);

const customValidator = new Validator();
customValidator.addRule(["cloudConfigMacros"], function* (macrosObj) {
  const fields = Object.keys(macrosObj).filter(
    (key) => !macrosObj[key].isOptional
  );

  for (const field of fields) {
    yield {
      result: Missing()(macrosObj[field].value, field, macrosObj),
      field: `cloudConfigMacros.${field}.value`,
    };
  }
});

const nameValidationRule = DebouncedRule()(validateClusterName);

const validator = new Validator();
validator.addRule(["name", "clusterprofile"], Missing());
validator.addRule(
  ["credential"],
  ApplyIf((value, key, data) => {
    return !(data.clusterType === "edge");
  }, Missing())
);
validator.addRule(
  ["cloudType"],
  ApplyIf((value, key, data) => {
    return data.clusterType === "edge";
  }, Missing())
);
validator.addRule(["tags"], areValidKubernetesTags({ onlySpectroTags: true }));
validator.addRule(["name"], (value) => {
  // change the min/max length in case values for the name rules change
  if (!value || value.length < 3 || value.length > 32) {
    return;
  }
  const promise = nameValidationRule(value);
  store.dispatch({
    type: "CLUSTER_NAME_VALIDATION",
    promise,
  });
  return promise;
});
validator.addRule(["name"], isKubernetesName());
validator.addRule(["name"], MaxLength(32));
validator.addRule(["name"], MinLength(3));

validator.addRule(function* (data) {
  const cloudType = getSelectedCloud(store.getState());
  const isCustom = appEnv.isCustomCloud(cloudType);
  const type = isCustom ? "custom" : cloudType;
  const validators = {
    aws: awsFieldsValidator,
    eks: awsFieldsValidator,
    vsphere: vmwareFieldsValidator,
    azure: azureFieldsValidator,
    aks: azureFieldsValidator,
    gcp: googleFieldsValidator,
    gke: gkeFieldsValidator,
    maas: maasFieldsValidator,
    openstack: openStackFieldsValidator,
    libvirt: libvirtFieldsValidator,
    "edge-native": edgeNativeFieldsValidator,
    tke: tencentFieldsValidator,
    [COXEDGE_ENVIRONMENT.apiKey]: coxClusterValidator,
    custom: customValidator,
  };

  if (Object.keys(validators).includes(type)) {
    yield validators[type];
  }
});
validator.addRule(["vpcid"], StaticPlacementRule());
validator.addRule(
  ["controlPlaneSubnet", "workerSubnet"],
  (value, key, data) => {
    if (!data.vnetName) {
      return false;
    }
    if (data.hasDisablePropertiesRequest) {
      return false;
    }

    return Missing()(value, key, data);
  }
);

validator.addRule("externalIPs", areValidIPTags());
validator.addRule(
  "loadBalancerSourceRanges",
  areValidIPRangesTags({
    genericMessage: () => i18n.t("One or more source ranges are invalid"),
  })
);
validator.addRule(
  "ingressHost",
  ApplyIf((value, key, data) => {
    return data.clusterEndpointType === "Ingress";
  }, Missing())
);

validator.addRule(["nodePools"], nodePoolValidator);
validator.addRule(["nodePools"], function* (value, key, data) {
  const cloudType = getSelectedCloud(store.getState());
  const staticPlacementEnabled = data.networkType === "staticIP";

  const hasOnePoolWithoutTaints = value.find(
    (nodePool) => !nodePool.taints.length
  );

  yield {
    result: !hasOnePoolWithoutTaints
      ? i18n.t("At least one node pool should not contain taints")
      : false,
    field: "nodePools",
    showInWizardFooter: true,
  };

  if (cloudType === "vsphere") {
    for (const index in value) {
      const nodePool = value[index];
      const clusters = nodePool.domains.map((domain) => domain.cluster);

      for (const clusterIndex in clusters) {
        const cluster = clusters[clusterIndex];
        const duplicates = clusters.filter(
          (currentItem) => currentItem === cluster
        );
        yield {
          result:
            duplicates.length > 1 ? i18n.t("Clusters must be unique") : false,
          field: `nodePools.${index}.domains.${clusterIndex}.cluster`,
        };
      }

      for (const domainIndex in nodePool.domains) {
        const domain = nodePool.domains[domainIndex];
        yield {
          result:
            nodePool.isMaster &&
            nodePool.domains
              .map((domain) => domain.network)
              .some((network) => network !== "" && network !== domain.network)
              ? i18n.t("Control Plane nodes must share the same network")
              : false,
          field: `nodePools.${index}.domains.${domainIndex}.network`,
        };

        if (
          domainIndex === "0" &&
          nodePool.isMaster &&
          data.clusterType !== "edge"
        ) {
          yield {
            result:
              isEmpty(nodePool.domains[0].dns) && !staticPlacementEnabled
                ? i18n.t("Please define a DNS mapping")
                : false,
            field: `nodePools.${index}.domains.${domainIndex}.dns.spec.dnsName`,
          };
        }
      }
    }

    return;
  }

  if (cloudType === "aks") {
    const hasOneSystemPool = value.find(
      (nodePool) => nodePool.isSystemNodePool
    );

    for (const index in value) {
      yield {
        result: !hasOneSystemPool
          ? i18n.t("At least one pool must be set to be system")
          : false,
        field: `nodePools.${index}.isSystemNodePool`,
      };
    }
  }

  if (!["aws", "eks"].includes(cloudType)) {
    return;
  }

  for (const index in value) {
    const nodePool = value[index];

    if (cloudType === "eks") {
      if (data.staticPlacement) {
        yield {
          result:
            data.staticPlacement && !nodePool.azs.length
              ? "Select at least one AZ"
              : false,
          field: `nodePools.${index}.azs`,
        };
      }
    }

    for (const az of nodePool.azs) {
      yield {
        result:
          data.staticPlacement && !nodePool[`subnet_${az}`]?.length
            ? "Missing subnet for selected az"
            : false,
        field: `nodePools.${index}.subnet_${az}`,
      };
    }

    if (
      (nodePool.isMaster || nodePool.isControlPlane) &&
      data.staticPlacement &&
      !data.apiServerPrivate
    ) {
      const azs = mapAwsAzs(
        store.getState().cluster.details?.cloudConfigParams?.azs || []
      );
      const subnetOptions = getSubnetsForSelectedAz(store.getState());
      const azSubnets = getSubnetsForSelectedAz(store.getState());
      const initialAzs = store.getState().forms?.nodePool?.initialData?.azs;

      const hasPrivateAndPublicSubnet = nodePool.azs.every((az) => {
        const isAzDisabled =
          (azSubnets && !azSubnets[az]) ||
          initialAzs?.includes(az) ||
          azs.find((el) => el.label === az)?.disabled;

        if (isAzDisabled) {
          return true;
        }
        const subnets = nodePool[`subnet_${az}`]?.map((subnet) =>
          subnetOptions[az].find((subnetData) => subnetData.value === subnet)
        );

        return (
          subnets?.find((subnet) => subnet?.isPrivate) &&
          subnets?.find((subnet) => !subnet?.isPrivate)
        );
      });

      yield {
        result: !hasPrivateAndPublicSubnet
          ? "Please assign at least one private and one public subnet for each selected zone"
          : false,
        field: `nodePools.${index}.azs`,
      };
    }
  }
});

validator.addRule(["layers"], async function (value, key, data) {
  const clusterProfile = getSelectedClusterProfile(store.getState());
  if (!clusterProfile) {
    return false;
  }

  if (
    data.cloudType === "libvirt" ||
    (data.cloudType === "vsphere" && data.clusterType === "edge")
  ) {
    const systemProfile = clusterCreateProfileModule.state.profiles.find(
      (profile) => profile.type === "system"
    );

    if (!systemProfile) {
      notifications.warn({
        message: i18n.t("Add a system profile to continue"),
      });

      return i18n.t("Add a system profile to continue");
    }
  }
  let response;
  try {
    response = await api.post("v1/spectroclusters/validate/packs", {
      profiles: clusterCreateProfileModule.payload,
    });
  } catch (err) {
    notifications.error({
      message: i18n.t("Something went wrong"),
      description: err?.message,
    });

    return false;
  }

  const packsErrors = response.profiles.map((profile) => ({
    results: profile?.packs?.results,
    uid: profile.uid,
  }));
  clusterCreateProfileModule.actions.setLayersErrors(packsErrors);
  return packsErrors.length > 0 ? packsErrors : false;
});

function defaultRecurrency() {
  return {
    type: "never",
    recurrency: "",
  };
}

validator.addRule(["namespaces"], isValidNamespaceAllocation());

validator.addRule("namespace", isValidRegexNamespace());
validator.addRule("namespace", (value, key, data) => {
  if (data?.namespaces?.find((ns) => ns.namespaceName === value)) {
    return i18n.t("Namespaces must be unique");
  }
  return false;
});

async function submit(data) {
  const state = store.getState();
  const cloudType = getSelectedCloud(state);

  const payload = getPayload(state);

  let response = null;
  const isCustom = data.clusterType === "custom";
  try {
    if (isCustom) {
      response = await api.post(
        `v1/spectroclusters/cloudTypes/${cloudType}`,
        payload
      );
    } else {
      response = await api.post(`v1/spectroclusters/${cloudType}`, payload);
    }
  } catch (error) {
    notifications.error({
      message: i18n.t("Something went wrong when creating the cluster"),
      description: error.message,
    });
  }

  if (response?.uid) {
    store.dispatch(clearClusterTagsCache());
    const clusterOverviewPath = generatePath(CLUSTERS.TAB_DETAILS, {
      clusterCategory: "clusters",
      id: response.uid,
      tab: "overview",
    });
    historyService.push(clusterOverviewPath);
    notifications.success({
      message: `Cluster "${data.name}" has been created successfully. Deployment is in progress...`,
    });
  }
}

const Netmask = netmask.Netmask;
const defaultEdgeNativeBlock = new Netmask(DEFAULT_EDGE_NATIVE_INTERNAL_CIDR);

const clusterFormActions = createFormActions({
  validator,
  submit,
  init: async () => {
    const query = history.getQuery();
    const [, profileUid] = store
      .getState()
      .router.location?.pathname?.split("create/");

    const isAirgap = isAirgapEnv(store.getState());

    let clusterprofile;
    let selectedCloud = "";
    let clusterType = null;
    let isVsphereEdge = false;

    if (query?.cloudType && !profileUid) {
      let cloudType =
        query.cloudType === "baremetal" ? "libvirt" : query.cloudType;
      clusterType = query?.clusterType;
      selectedCloud = cloudType;

      store.dispatch({
        type: "SET_SELECTED_CLOUD_TYPE",
        selectedCloud: cloudType,
      });
    }

    if (profileUid) {
      await store.dispatch(fetchClusterProfile(profileUid));
      clusterprofile = getRawClusterProfile(store.getState());

      selectedCloud = clusterprofile?.spec?.published?.cloudType;
      const isBaremetalEnv = BAREMETAL_ENVS.includes(selectedCloud);
      const isCustom = appEnv.isCustomCloud(selectedCloud);
      if (isCustom) {
        clusterType = "custom";
      }

      if (MANAGED_TO_PURE_ENVIRONMENT[selectedCloud]) {
        selectedCloud = MANAGED_TO_PURE_ENVIRONMENT[selectedCloud];
      }

      store.dispatch({
        type: "SET_SELECTED_CLOUD_TYPE",
        selectedCloud,
      });

      if (isBaremetalEnv) {
        clusterType = "edge";
      }
    }

    return Promise.resolve({
      clusterType,
      sshKey: "",
      sshKeys: [],
      coxEdgeLoadBalancerConfig: {
        pops: "",
      },
      networkType: "dhcp",
      nodePoolsCount: 2,
      useFolderSufix: true,
      folder: "",
      imageTemplateFolder: "",
      dns: null,
      osPatchingScheduleOption: "never",
      patchOnBoot: !isAirgap,
      rebootIfRequired: !isAirgap,
      nodePools: [],
      name: "",
      appliance: "",

      vip: "",
      ip: "",
      internalCIDR: DEFAULT_EDGE_NATIVE_INTERNAL_CIDR,
      internalIp: defaultEdgeNativeBlock.first,

      cloudType: selectedCloud,
      credential: null,
      layers: "",
      endpointAccess: "private-public",
      ipAllocationMethod: "Dynamic",
      apiServerLBStaticIP: "",
      updateWorkerPoolsInParallel: true,
      isVsphereEdge,
      publicAccessCidrs: ["0.0.0.0/0"],
      privateAccessCidrs: ["0.0.0.0/0"],
      provider: "",
      hasDisablePropertiesRequest: false,

      kubebenchEnabled: false,
      kubebench: defaultRecurrency(),
      kubehunterEnabled: false,
      kubehunter: defaultRecurrency(),
      sonobuoyEnabled: false,
      sonobuoy: defaultRecurrency(),

      backupPrefix: "",
      location: null,
      schedule: "never",
      occurrence: undefined,
      includeAllDisks: true,
      includeClusterResourceMode: "Auto",
      expiryPeriod: EXPIRY_PERIODS[0].value,
      expiryHours: FOUR_MONTHS_IN_HOURS,
      backupedNamespaces: [],
      withTaints: false,
      fargateProfiles: [],

      clusterRoleBindings: [],
      roleBindings: [],
      clusterprofile: clusterprofile?.guid,
      preselectedProfile: !!profileUid,
      profileType: clusterprofile?.spec?.published?.type || "",
      namespace: "",
      namespaces: [],
      initializedProfileStack: false,
      initializedRegions: false,
      edgeHostsFetched: false,

      // virtual clusters
      isHostCluster: false,
      externalTrafficPolicy: "cluster",
      clusterEndpointType: "LoadBalancer",

      geocoding: {
        location: "",
        markerGuid: null,
      },
    });
  },
});

export const createClusterRoleBindingsForm = createRoleBindingsFormFactory({
  formActions: clusterFormActions,
  formModuleName: "cluster",
  getClusterUid: (state) => getCluster(state)?.metadata?.uid,
});

export const geocodingForm = createGeocodingFormFactory({
  formActions: clusterFormActions,
  formModuleName: "cluster",
});

export default clusterFormActions;
