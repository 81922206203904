import React from "react";
import Button from "components/ui/Button";
import Icon from "components/ui/Icon";
import { faClockRotateLeft } from "@fortawesome/pro-light-svg-icons";
import styled from "styled-components";
import i18next from "i18next";

const SuffixWrap = styled.div`
  height: 30px;
`;

export function getVariableDefaultValue(variable) {
  if (variable?.format === "boolean") {
    return variable?.defaultValue === "true";
  }
  return variable?.defaultValue || "";
}

export function getVariableDetails(
  { name, profileName } = {},
  profileVariables = []
) {
  return profileVariables.find(
    (profileVariable) =>
      profileVariable.name === name &&
      profileVariable.profile.name === profileName
  );
}

export const extractVariablesFromProfiles = (profiles = []) => {
  return profiles.reduce((accumulator, profile) => {
    const variables = profile?.variables || [];
    variables.forEach((variable) => {
      accumulator.push({
        ...variable,
        profile: {
          name: profile.metadata.name,
          uid: profile.metadata.uid,
          type: profile?.spec?.published?.type,
          version: profile.spec.version,
        },
      });
    });

    return accumulator;
  }, []);
};

function getFieldKind(variable) {
  if (variable.format === "boolean") return "boolean";
  if (variable.isSensitive) return "masked";
  if (variable.format === "number") return "number";
  return "string";
}

export function getFieldPropsFromVariable({
  prefix,
  variable,
  readOnly = false,
  fieldValue,
  onUseDefaultValue,
}) {
  const hasDefaultValue = fieldValue === variable.defaultValue;
  const showUseDefault =
    !readOnly &&
    !hasDefaultValue &&
    !!variable.defaultValue &&
    !variable.immutable;
  const kind = getFieldKind(variable);

  return {
    name: `${prefix}.${variable?.guid}.value`,
    label: variable.displayName,
    description:
      hasDefaultValue && variable.defaultValue
        ? i18next.t("Uses default value")
        : "",
    helperText: variable.description,
    required: !!variable?.required,
    kind,
    readOnly: readOnly || !!variable?.immutable,
    suffix: (
      <SuffixWrap>
        {showUseDefault ? (
          <Button
            data-qa="use-default-button"
            onClick={onUseDefaultValue}
            secondary={true}
            variant={"flat"}
          >
            <Icon awesome={faClockRotateLeft} />
            <span>{i18next.t("Use Default")}</span>
          </Button>
        ) : null}
      </SuffixWrap>
    ),
  };
}
