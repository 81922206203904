import ModalService from "services/modal";
import i18n from "i18next";
import createFormActions from "modules/form/actions";
import { WizardActions } from "modules/wizard/actions";
import store, { getStoreEntity } from "services/store";
import Validator from "services/validator";
import historyService from "services/history";
import notifications from "services/notifications";
import ListActions from "modules/list/actions";
import { ClusterProfileSchema, PairingRequestSchema } from "utils/schemas";
import api from "services/api";
import {
  getFormattedPayload,
  getPairingRequestsDetails,
  getPairingRequestsMetadata,
} from "./selectors";
import i18next from "i18next";
import AsyncAction from "modules/asyncAction";
import { pairingRequestsMetadataFetcher } from "./services";
import {
  CLUSTER_PROFILE_CONTEXT_OPTIONS,
  CLUSTER_PROFILE_TYPES,
} from "utils/constants";
import {
  getCurrentPacks,
  getNewPacks,
  getFormattedNewProfiles,
} from "./selectors";
import { pairingClusterDetailsFetcher } from "./services";
import { parseTagsForInput } from "utils/parsers";
import {
  areValidKubernetesTags,
  DebouncedRule,
  isKubernetesName,
  MaxLength,
  MinLength,
  Missing,
} from "services/validator/rules";
import { generatePath } from "react-router";
import { CLUSTERS } from "utils/constants/routes";

export const waitingForPairingModal = new ModalService("waitingForPairing");

export function fetchPairingRequests() {
  return async (dispatch, getState) => {
    await dispatch(pairingRequestsMetadataFetcher.fetch());
    const pairingRequestsMetadata = getPairingRequestsMetadata(getState());

    const promises = pairingRequestsMetadata.map((pairingRequest) => {
      return store.dispatch({
        type: "FETCH_PAIRING_REQUEST_DETAILS",
        promise: api.get(
          `v1/spectroclusters/edge-native/pair/${pairingRequest?.pairingUid}`
        ),
        schema: PairingRequestSchema,
      });
    });
    await Promise.allSettled(promises);
  };
}

export function onOpenWaitingForPairingModal() {
  return async function thunk(dispatch) {
    await dispatch(fetchPairingRequests());
    return waitingForPairingModal.open();
  };
}

export const CLUSTER_PAIRING_WIZARD = "cluster-pairing";
export const CLUSTER_PAIRING_FORM_MODULE = "cluster-pairing";
export const PROFILES_MATCHING_DRAWER = "match-profile-list";
export const PROFILES_MATCHING_MODAL = "match-profile-modal";

const validator = new Validator();

const validateClusterName = async (value) => {
  let error;
  const promise = api.get(`v1/spectroclusters/validate/name?name=${value}`);

  try {
    await promise;
  } catch (err) {
    let message;
    if (err.code === "InvalidName") {
      message = i18next.t(
        `Cluster name must consist of lower case alphanumeric characters and must start with an alphabet`
      );
    }

    if (err.code === "ResourceAlreadyExists") {
      message = i18next.t(
        `Cluster with the name "{{ value }}" already exists`,
        {
          value,
        }
      );
    }

    error = {
      result: message,
      field: "name",
    };
  }

  return error || false;
};

const nameValidationRule = DebouncedRule()(validateClusterName);

validator.addRule(["name", "profiles"], Missing());
validator.addRule("name", isKubernetesName());
validator.addRule(["name"], (value) => {
  if (!value || value.length < 3 || value.length > 32) {
    return;
  }
  const promise = nameValidationRule(value);
  store.dispatch({
    type: "CLUSTER_PAIR_NAME_VALIDATION",
    promise,
  });
  return promise;
});
validator.addRule(["name"], MaxLength(32));
validator.addRule(["name"], MinLength(3));
validator.addRule(["tags"], areValidKubernetesTags({ onlySpectroTags: true }));

export const clusterPairingFormActions = createFormActions({
  validator,
  init: async () => {
    const clusterData =
      pairingClusterDetailsFetcher.selector(store.getState())?.result || {};
    return Promise.resolve({
      name: clusterData?.metadata?.name || "",
      uid: clusterData?.metadata?.uid,
      description: clusterData?.metadata?.annotations?.description,
      tags: parseTagsForInput(clusterData?.metadata?.labels),
      profiles: [],
    });
  },
  submit: async (data) => {
    const payload = getFormattedPayload(store.getState());

    let response = null;
    try {
      response = await api.post(
        `v1/spectroclusters/edge-native/spc/pair`,
        payload
      );
    } catch (err) {
      notifications.error({
        message: i18n.t("Something went wrong when pairing the cluster"),
        description: err?.message,
      });
    }

    if (response?.uid) {
      const clusterOverviewPath = generatePath(CLUSTERS.TAB_DETAILS, {
        clusterCategory: "clusters",
        id: response.uid,
        tab: "overview",
      });
      historyService.push(clusterOverviewPath);
      notifications.success({
        message: `Cluster "${data.name}" has been created successfully. Deployment is in progress...`,
      });
    }
  },
});

export const clusterPairingWizardActions = new WizardActions({
  formActions: clusterPairingFormActions,
  fieldsToValidate() {
    return {
      0: ["name"],
      1: ["profiles"],
    };
  },
  getDescription({ id }) {
    const formData = store.getState().forms?.[CLUSTER_PAIRING_FORM_MODULE].data;

    if (id === "basic-information") {
      return formData.name;
    }

    if (["profiles-matching", "profile-review"].includes(id)) {
      return i18n.t("Completed");
    }
  },
  steps: [
    {
      title: () => i18n.t("Basic Information"),
      id: "basic-information",
    },
    {
      title: () => i18n.t("Profiles Matching"),
      id: "profiles-matching",
    },
    {
      title: () => i18n.t("Profile Review"),
      id: "profile-review",
    },
    {
      title: () => i18n.t("Summary"),
      id: "summary",
    },
  ],
});

export function initClusterPairingWizard() {
  return async function thunk(dispatch) {
    dispatch(clusterPairingWizardActions.initialize(CLUSTER_PAIRING_WIZARD));
    await dispatch(
      clusterPairingFormActions.init({ module: CLUSTER_PAIRING_FORM_MODULE })
    );
  };
}

export const cancelClusterPairingConfirmation = new ModalService(
  "cancelClusterPairingModal"
);

export const profilesMatchingDrawer = new ModalService(
  PROFILES_MATCHING_DRAWER
);
export const profilesMatchingModal = new ModalService(PROFILES_MATCHING_MODAL);

export function onCancelClusterPairingModal() {
  cancelClusterPairingConfirmation.open().then(() => {
    historyService.push("/clusters/overview");
  });
}

export function onSubmitClusterPairingForm() {
  return async (dispatch) => {
    try {
      await dispatch(
        clusterPairingFormActions.submit({
          module: CLUSTER_PAIRING_FORM_MODULE,
        })
      );
    } catch (error) {
      notifications.warn({
        message: i18n.t(
          "There are validation errors. Please fix them in order to be able to pair"
        ),
        description: error?.message,
      });
    }
  };
}

export function onValidateClusterPairingForm() {
  return async (dispatch, getState) => {
    const validationPaylod = getFormattedPayload(getState());
    const promise = api.post(
      `v1/spectroclusters/edge-native/spc/pair/validate`,
      validationPaylod
    );

    dispatch({
      type: "VALIDATE_CLUSTER_PAIR",
      promise,
    });

    try {
      await promise;
      await dispatch(
        clusterPairingWizardActions.nextStep(CLUSTER_PAIRING_WIZARD)
      );
    } catch (error) {
      notifications.warn({
        message: i18n.t(
          "There are validation errors. Please fix them in order to be able to pair"
        ),
        description: error.message,
      });
    }
  };
}

const packsFiltersFetcher = () => {
  const currentPacks = getCurrentPacks(store.getState());
  return {
    profileTypes: {
      displayName: "Type",
      name: "profileTypes",
      options: CLUSTER_PROFILE_TYPES,
    },
    scope: {
      displayName: "Scope",
      name: "scope",
      options: CLUSTER_PROFILE_CONTEXT_OPTIONS,
      mode: "single",
    },
    packs: {
      displayName: "Packs",
      name: "packs",
      options: currentPacks,
    },
    packName: {
      name: "packName",
    },
  };
};

export const profileMatchingListActions = new ListActions({
  schema: [ClusterProfileSchema],
  loadStrategy: "all",
  fetchData: async ({
    limit,
    sort,
    packName = "",
    sortField,
    sortOrder,
    cloudTypes = [],
    profileTypes = [],
    scope = "",
    continue: continueToken,
    packs: queryPacks,
  }) => {
    const filters = {
      filter: {
        profileName: { contains: packName },
        profileType: profileTypes,
        environment: cloudTypes,
        scope,
      },
      sort: sortField ? [{ field: sortField, order: sortOrder }] : sort,
    };
    const response = await api.post(
      `v1/dashboard/clusterprofiles?limit=${limit}${
        continueToken ? `&continue=${continueToken}` : ""
      }`,
      filters
    );
    const { items = [] } = response;
    const packs = getCurrentPacks(store.getState());
    if (queryPacks?.length) {
      const filterByPacks = packs.filter((pack) =>
        queryPacks.includes(pack.packUid)
      );

      const filteredItems = items.filter((profile) => {
        const packs = profile.specSummary.published.packs || [];
        return filterByPacks.some((initialPack) => {
          return packs.find(
            (pack) =>
              pack.name === initialPack.name &&
              (pack.tag === initialPack.tag ||
                pack.version === initialPack.version)
          );
        });
      });
      return {
        ...response,
        items: filteredItems,
      };
    }
    return response;
  },
  fetchFilters: packsFiltersFetcher,
  filtersConfig: {
    data: {
      profileTypes: {
        componentType: "checkbox",
        pinned: true,
      },
      scope: {
        componentType: "checkbox",
        pinned: true,
      },
      packs: {
        componentType: "checkbox",
        pinned: true,
      },
      packName: {
        componentType: "search",
        search: true,
        placeholder: "Filter by pack name",
        operator: "contains",
      },
    },
    order: ["profileTypes", "scope", "packs"],
    showExtendedFilters: false,
  },
});

export const fetchAllProfiles = () => {
  return async (dispatch, getState) => {
    const newListState = getState().list[PROFILES_MATCHING_DRAWER];
    if (newListState.nextToken) {
      await dispatch(
        profileMatchingListActions.nextPage(PROFILES_MATCHING_DRAWER)
      );
      await dispatch(fetchAllProfiles());
    }
  };
};

const validateConfig = () => {
  return (dispatch, getState) => {
    const profilesToValidate = getState().clusterPair.profilesToValidate;
    const currentPacksState = getCurrentPacks(getState()).map(
      ({ matchState }) => matchState
    );
    const newPacks = getNewPacks(getState()).map(
      ({ matchState }) => matchState
    );
    let errorReason = "";
    if (!profilesToValidate.length) {
      errorReason = "missingNewConfiguration";
    }
    if (currentPacksState.some((state) => state === "unmatched")) {
      errorReason = "runningPacksMismatch";
    }
    if (newPacks.some((state) => state === "unmatched")) {
      errorReason = "newPacksMismatch";
    }

    if (errorReason) {
      dispatch({
        type: "PAIR_VALIDATION_ERROR",
        errorReason,
      });
    } else {
      dispatch({
        type: "CLEAR_PAIR_VALIDATION_ERROR",
      });
    }
  };
};

const setProfilesToValidate = () => {
  return (dispatch, getState) => {
    const selectedProfiles = getState().clusterPair.selectedProfiles;
    const profilesToValidate = [...getState().clusterPair.profilesToValidate];
    const selectedToSwap = getState().clusterPair.selectedToSwap;
    const swapIndex = profilesToValidate.findIndex(
      (guid) => guid === selectedToSwap
    );

    if (!selectedToSwap || swapIndex < 0) {
      dispatch({
        type: "SUBMIT_SELECTED_PROFILES",
        guids: selectedProfiles,
      });
    } else {
      const newSelections = selectedProfiles.filter(
        (profile) => !profilesToValidate.includes(profile)
      );
      profilesToValidate.splice(swapIndex, 1, ...newSelections);
      dispatch({
        type: "SUBMIT_SELECTED_PROFILES",
        guids: profilesToValidate,
      });
    }
    dispatch(validateConfig());
  };
};

const initProfilesDrawer = ({ initialSelection, packs }) => {
  return async (dispatch) => {
    dispatch({
      type: "PROFILES_DRAWER_INIT",
      initialSelection,
    });
    const initialQuery = {
      cloudTypes: ["all", "edge-native"],
      packs,
      sort: [
        {
          field: "lastModifiedTimestamp",
          order: "desc",
        },
      ],
      limit: 20,
    };

    await dispatch(
      profileMatchingListActions.initialize(
        PROFILES_MATCHING_DRAWER,
        initialQuery
      )
    );

    profilesMatchingDrawer.open().then(() => {
      dispatch(setProfilesToValidate());
      dispatch(
        profileMatchingListActions.clearPersistedFilters(
          PROFILES_MATCHING_DRAWER
        )
      );
    });
  };
};

export function closeProfilesDrawer() {
  return (dispatch) => {
    profilesMatchingDrawer.close();
    dispatch(
      profileMatchingListActions.clearPersistedFilters(PROFILES_MATCHING_DRAWER)
    );
  };
}

export function onAddProfile() {
  return async function thunk(dispatch, getState) {
    const currentPacks = getCurrentPacks(getState());
    const profilesToValidate = getState().clusterPair.profilesToValidate;

    dispatch(
      initProfilesDrawer({
        initialSelection: profilesToValidate,
        packs: currentPacks.map(({ packUid }) => packUid),
      })
    );
    dispatch({ type: "CLEAR_SWAP_SELECTION" });
  };
}

function onSwapProfile() {
  return async function thunk(dispatch, getState) {
    const currentPacks = getCurrentPacks(getState());
    const profilesToValidate = [...getState().clusterPair.profilesToValidate];
    const selectedToSwap = getState().clusterPair.selectedToSwap;

    const swapIndex = profilesToValidate.findIndex(
      (guid) => guid === selectedToSwap
    );
    if (swapIndex > -1) {
      profilesToValidate.splice(swapIndex, 1);
    }

    dispatch(
      initProfilesDrawer({
        initialSelection: profilesToValidate,
        packs: currentPacks.map(({ packUid }) => packUid),
      })
    );
  };
}

export function selectProfile(profileKeys) {
  return function thunk(dispatch) {
    dispatch({
      type: "UPDATE_SELECTED_PROFILES",
      profileKeys,
    });
  };
}

export function validatePairConfiguration() {
  return (dispatch, getState) => {
    dispatch(validateConfig());
    const isConfigurationValid = getState().clusterPair.isConfigurationValid;
    if (isConfigurationValid) {
      profilesMatchingModal.resolve();
    }
  };
}

export function confirmPairConfiguration() {
  return (dispatch, getState) => {
    const newProfiles = getFormattedNewProfiles(getState());

    dispatch(
      clusterPairingFormActions.onChange({
        module: CLUSTER_PAIRING_FORM_MODULE,
        name: "profiles",
        value: newProfiles,
      })
    );
    const firstPack = newProfiles[0]?.packs[0] || {};
    dispatch(
      selectProfilePack({
        name: firstPack.name || "",
        tag: firstPack.tag || "",
        version: firstPack.version || "",
      })
    );
  };
}

export function onEditConfig() {
  return (dispatch) => {
    profilesMatchingModal.open().then(() => {
      dispatch(confirmPairConfiguration());
    });
  };
}

export function swapProfile({ guid }) {
  return (dispatch) => {
    dispatch({
      type: "SELECT_TO_SWAP",
      guid,
    });
    dispatch(onSwapProfile());
  };
}

export function removeProfile(profile) {
  return (dispatch) => {
    const { guid } = profile;
    dispatch({
      type: "REMOVE_PROFILE",
      guid,
    });
    dispatch(validateConfig());
  };
}

export function openMatchModal() {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_PAIR_VALIDATION_ERROR",
    });
    dispatch({
      type: "CLUSTER_PAIR_INIT",
    });
    profilesMatchingModal.open().then(() => {
      dispatch(confirmPairConfiguration());
    });
  };
}

export function selectProfilePack({ name, tag, version }) {
  return (dispatch) => {
    dispatch({ type: "SET_SELECTED_PACK", name, tag, version });
  };
}

export function setSearch(searchTerm) {
  return (dispatch) => {
    dispatch({
      type: "SET_SEARCH_TERM",
      searchTerm,
    });
  };
}

export const rejectPairingRequestAsyncAction = new AsyncAction({
  promise: async (guid) => {
    const pairingRequest = getStoreEntity(guid, PairingRequestSchema);
    try {
      await api.patch(
        `v1/spectroclusters/pair/${pairingRequest?.metadata?.uid}/reject`
      );
    } catch (err) {
      notifications.error({
        message: i18next.t(
          "Something went wrong while rejecting the '{{name}}' pairing request",
          { name: pairingRequest?.metadata?.name }
        ),
        description: err.message,
      });
      return;
    }

    notifications.success({
      message: i18next.t(
        "Pairing request for '{{name}}' was rejected successfully",
        { name: pairingRequest?.metadata?.name }
      ),
    });

    await store.dispatch(fetchPairingRequests());
    const clustersToPair = getPairingRequestsDetails(store.getState());

    if (!clustersToPair?.length) {
      waitingForPairingModal.close();
    }
  },
});

export function onRejectPairingRequest(guid) {
  return async () => {
    return rejectPairingRequestAsyncAction.key(guid).trigger();
  };
}
