import React from "react";
import styled from "styled-components";

const InfoCardContainer = styled.div`
  display: flex;
  align-items: flex-start;
  background-color: ${({ theme }) => theme.backgroundColor || "#DCDAF9"};
  border-radius: 4px;
  padding: 16px;
  font-size: 14px;
  max-width: 600px;
`;

const IconWrapper = styled.div`
  margin-right: 16px;
  flex-shrink: 0;

  svg {
    width: 24px;
    height: 24px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Title = styled.div`
  font-size: 16px;
  color: #2d2d2d;
`;

const Description = styled.div`
  margin: 0 0 4px;
  font-size: 14px;
  color: #545f7e;
`;

const Badge = styled.span`
  display: inline-block;
  margin: 4px 0px 8px;
  padding: 4px 12px;
  background-color: ${({ theme }) => theme.badgeColor || "#7D77CA"};
  border-radius: 12px;
  font-size: 12px;
  color: #ffffff;
`;

const InfoCard = ({
  title,
  description,
  badgeText,
  icon,
  theme = {},
  children,
}) => {
  return (
    <InfoCardContainer theme={theme}>
      {icon && <IconWrapper>{icon}</IconWrapper>}
      <ContentWrapper>
        {title && <Title>{title}</Title>}
        {description && <Description>{description}</Description>}
        {badgeText && <Badge theme={theme}>{badgeText}</Badge>}
        {children}
      </ContentWrapper>
    </InfoCardContainer>
  );
};

export default InfoCard;
