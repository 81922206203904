import React, { Suspense } from "react";
import Loader from "components/ui/Loader";

const LazyPackReadMe = React.lazy(() => import("./PackReadMe"));

const PackReadMe = (props) => (
  <Suspense fallback={<Loader />}>
    <LazyPackReadMe {...props} />
  </Suspense>
);

export default PackReadMe;
