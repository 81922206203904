import { init as initApm } from "@elastic/apm-rum";
import appEnv from "services/app";
import store from "services/store";
import isFQDN from "validator/lib/isFQDN";

const SERVICE_NAME = "optic";

const getEnvironment = () => {
  const rootDomain =
    store.getState()?.auth?.currentOrganization?.rootDomain || "";

  if (!rootDomain) {
    return "";
  }

  if (isFQDN(rootDomain)) {
    return rootDomain?.split(".")?.shift();
  }

  return rootDomain;
};

class ElasticRUM {
  _apm = null;

  async initialize() {
    if (this._apm || !appEnv?.env?.ELASTIC_SERVER_URL || import.meta.env.DEV) {
      return;
    }

    try {
      const environment = getEnvironment();
      this._apm = initApm({
        serviceName: SERVICE_NAME,
        serverUrl: appEnv?.env?.ELASTIC_SERVER_URL,
        serviceVersion: appEnv?.env?.PALETTE_VERSION || "unknown",
        environment: environment,
      });
    } catch (error) {
      console.error("Elastic APM initialization failed:", error);
    }
  }

  get apm() {
    return this._apm;
  }
}

const apm = new ElasticRUM();
export default apm;
