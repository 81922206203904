import { getPair, mergeYaml } from "utils/yaml";
import * as YAML from "yaml";

function getPoolYamlDocsToUpdate(yamlDocs) {
  return yamlDocs.filter((doc) => {
    const kindConstruct = getPair(doc, ["kind"]);
    return (
      kindConstruct &&
      (["MachineDeployment", "KubeadmControlPlane"].includes(
        kindConstruct.value.value
      ) ||
        ["ManagedControlPlane", "ManagedMachinePool", "MachinePool"].some(
          (type) => kindConstruct.value.value.includes(type)
        ))
    );
  });
}
function updateNodePoolYaml(yaml, yamlToMerge) {
  let yamlDocs;
  try {
    yamlDocs = YAML.parseAllDocuments(yaml);
  } catch (error) {
    return yaml;
  }
  const yamlDocsToUpdate = getPoolYamlDocsToUpdate(yamlDocs);

  if (!yamlDocsToUpdate.length) {
    return yaml;
  }

  return yamlDocs
    .map((doc) => {
      if (yamlDocsToUpdate.includes(doc)) {
        return mergeYaml(doc.toString(), yamlToMerge);
      }

      return doc.toString();
    })
    .join("\n");
}

export function updateNodePoolNameInYAML(yaml, name) {
  const updatedYaml = updateNodePoolYaml(
    yaml,
    `
metadata:
  name: "${name}"
`
  );

  let yamlDocs = [];
  try {
    yamlDocs = YAML.parseAllDocuments(updatedYaml);
  } catch (error) {
    return yaml;
  }

  // now we update only worker pool
  // name sync has some addition side-effects
  // because a worker pool is not a singleton
  // and the resources it creates must be unique
  // in the cluster
  const yamlDocsToUpdate = getPoolYamlDocsToUpdate(yamlDocs);
  if (!yamlDocsToUpdate.length) {
    return updatedYaml;
  }
  const referencedResources = [
    "spec.template.spec.bootstrap.configRef",
    "spec.template.spec.infrastructureRef",
  ];

  referencedResources.forEach((resourcePath) => {
    yamlDocsToUpdate.forEach((yamlDoc) => {
      const resource = getPair(yamlDoc, resourcePath.split("."));
      if (!resource) {
        return;
      }
      const resourceKind = getPair(yamlDoc, [
        ...resourcePath.split("."),
        "kind",
      ]);
      if (!resourceKind) {
        return false;
      }

      const resourceDoc = yamlDocs.find((doc) => {
        const kindConstruct = getPair(doc, ["kind"]);
        return (
          kindConstruct &&
          kindConstruct.value.value === resourceKind.value.value
        );
      });

      if (!resourceDoc) {
        return;
      }

      const nameConstruct = getPair(resourceDoc, ["metadata", "name"]);
      const resourceName = getPair(yamlDoc, [
        ...resourcePath.split("."),
        "name",
      ]);
      if (!nameConstruct || !resourceName) {
        return;
      }

      resourceName.value.value = name;
      nameConstruct.value.value = name;
    });
  });

  return yamlDocs.map((doc) => doc.toString()).join("\n");
}

export function updateNodePoolSizeInYAML(yaml, size) {
  return updateNodePoolYaml(
    yaml,
    `
spec:
  replicas: ${size}
`
  );
}

export function populateNodePoolYAML({ yaml, poolName }) {
  const withName = updateNodePoolNameInYAML(yaml, poolName);

  return withName;
}

export function extractNodePoolFieldsFromYAML(yaml) {
  let yamlDocs;
  try {
    yamlDocs = YAML.parseAllDocuments(yaml);
  } catch (error) {
    return {};
  }
  const yamlDocIndex = yamlDocs.findIndex((doc) => {
    const kindConstruct = getPair(doc, ["kind"]);
    return (
      kindConstruct &&
      ["MachineDeployment", "KubeadmControlPlane"].includes(
        kindConstruct.value.value
      )
    );
  });

  if (yamlDocIndex === -1) {
    return {};
  }

  const yamlDoc = yamlDocs[yamlDocIndex];
  const nameConstruct = getPair(yamlDoc, ["metadata", "name"]);
  const sizeConstruct = getPair(yamlDoc, ["spec", "replicas"]);
  return {
    poolName: nameConstruct && nameConstruct.value.value,
    size: sizeConstruct && sizeConstruct.value.value,
  };
}

export function populateClusterConfig({ yaml, masterPoolName }) {
  let yamlDocs;
  try {
    yamlDocs = YAML.parseAllDocuments(yaml);
  } catch (error) {
    return yaml;
  }
  const yamlDocIndex = yamlDocs.findIndex((doc) => {
    const kindConstruct = getPair(doc, ["kind"]);
    return kindConstruct?.value?.value === "Cluster";
  });

  if (yamlDocIndex === -1) {
    return yaml;
  }

  const yamlDoc = yamlDocs[yamlDocIndex];
  const refKind = getPair(yamlDoc, ["spec", "controlPlaneRef", "kind"]);
  let infraReplacement = "";
  if (refKind?.value?.value.includes("ManagedControlPlane")) {
    infraReplacement = `
  infrastructureRef:
    name: "${masterPoolName}"
`;
  }

  const updatedDoc = mergeYaml(
    yamlDoc.toString(),
    `
spec:
  controlPlaneRef:
    name: "${masterPoolName}"
  ${infraReplacement}
`
  );
  return yamlDocs
    .map((doc, index) => {
      if (index === yamlDocIndex) {
        return updatedDoc;
      }

      return doc.toString();
    })
    .join("\n");
}
