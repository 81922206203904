import cloneDeep from "lodash/cloneDeep";
import { BASE_LAYERS } from "utils/constants/clusterprofile";
import {
  extractVariablesFromProfiles,
  getVariableDefaultValue,
} from "utils/variablesHelpers";

export function reversePacks(profiles) {
  return (profiles || []).map((profile) => {
    const profileClone = cloneDeep(profile);
    (profileClone.spec?.published?.packs || []).reverse();
    return profileClone;
  });
}

export const categorizeProfiles = (profiles = [], markForDeletion = []) => {
  let infraProfile = profiles.find(
    (profile) => !["add-on", "system"].includes(profile.type)
  );
  const systemProfile = profiles.find((profile) => profile.type === "system");
  const addonProfiles = profiles
    .filter((profile) => profile.type === "add-on")
    .map((profile) => ({
      ...profile,
      markedForDeletion: markForDeletion.includes(profile.guid),
    }));
  let extraAddon = null;
  if (infraProfile) {
    const layers = infraProfile.spec.published.packs || [];
    const extraLayers = layers.filter(
      (pack) => !BASE_LAYERS.includes(pack.spec?.layer)
    );

    if (extraLayers.length) {
      const baseLayers = layers.filter((pack) =>
        BASE_LAYERS.includes(pack.spec?.layer)
      );
      extraAddon = {
        ...infraProfile,
        linked: true,
        spec: {
          ...infraProfile.spec,
          published: {
            ...infraProfile.spec.published,
            packs: extraLayers,
          },
        },
      };
      infraProfile = {
        ...infraProfile,
        linked: true,
        spec: {
          ...infraProfile.spec,
          published: {
            ...infraProfile.spec.published,
            packs: baseLayers,
          },
        },
      };
    }
  }

  return {
    infra: extraAddon ? { ...infraProfile, linked: true } : infraProfile,
    addons: extraAddon ? [...addonProfiles, extraAddon] : addonProfiles,
    system: systemProfile,
  };
};

export const getVariablesFormPayload = (formData, profiles = []) => {
  const variables = extractVariablesFromProfiles(profiles);

  return variables.reduce(
    (accumulator, variable) => ({
      ...accumulator,
      [variable.guid]: {
        profileName: variable.profile.name,
        profileUid: variable.profile.uid,
        name: variable.name,
        hidden: variable.hidden,
        value:
          formData?.variables?.[variable?.guid]?.value ||
          (variable.format === "boolean"
            ? variable.value === "true"
            : variable.value) ||
          getVariableDefaultValue(variable),
        guid: variable.guid,
      },
    }),
    {}
  );
};

export const getGroupedVariables = (profiles) => {
  const variables = extractVariablesFromProfiles(profiles);

  return variables
    .filter((variable) => !variable.hidden)
    .reduce((accumulator, variable) => {
      const profileName = variable.profile.name;
      const existingProfile = accumulator.find(
        ({ profile }) => profile.name === profileName
      );

      if (existingProfile) {
        existingProfile.variables.push(variable);
      } else {
        accumulator.push({
          profile: {
            ...variable.profile,
          },
          variables: [variable],
        });
      }

      return accumulator;
    }, []);
};

export function getProfilesWithErrors(groupedVariables = [], errors = []) {
  const fieldsWithErrors = errors.map((error) => error.field.split(".")?.[1]);

  return groupedVariables
    .filter((variablesGroup) => {
      const variablesGuids = (variablesGroup.variables || []).map(
        (variable) => variable.guid
      );
      return variablesGuids.some((variableGuid) =>
        fieldsWithErrors.includes(variableGuid)
      );
    })
    .map(({ profile }) => profile?.uid);
}
