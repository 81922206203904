import createFormActions from "modules/form/actions";
import store from "services/store";
import Validator from "services/validator";
import { isValidVariable } from "services/validator/rules";
import { getVariablesFormPayload } from "./selectors";
import {
  extractVariablesFromProfiles,
  getVariableDetails,
} from "utils/variablesHelpers";

export default function createProfileFormActions(guid) {
  const getState = () => {
    return store.getState().profileStack[guid];
  };

  const validator = new Validator();
  validator.addRule("variables", function* (value, key, data) {
    const profiles = getState().profiles;
    const profileVariables = extractVariablesFromProfiles(profiles);

    for (const key of Object.keys(data.variables)) {
      yield {
        field: `variables.${key}.value`,
        result: isValidVariable({
          ...getVariableDetails(data.variables[key], profileVariables),
        })(data.variables[key]),
      };
    }
  });

  return createFormActions({
    validator,
    init: () => {
      const variables = getVariablesFormPayload({}, getState().profiles);
      return Promise.resolve({
        variables,
      });
    },
    submit: async (data) => {
      return data;
    },
  });
}
